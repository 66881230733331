@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css";
.el-breadcrumb:after, .el-breadcrumb:before, .el-button-group:after, .el-button-group:before, .el-form-item:after, .el-form-item:before, .el-form-item__content:after, .el-form-item__content:before {
  content: "";
  display: table;
}

.el-breadcrumb:after, .el-button-group:after, .el-color-dropdown__main-wrapper:after, .el-dialog__header:after, .el-form-item:after, .el-form-item__content:after, .el-menu:after, .el-picker-panel__body-wrapper:after, .el-picker-panel__body:after, .el-row:after, .el-slider:after, .el-tabs {
  clear: both;
}

.el-checkbox-button__original, .el-pagination--small .arrow.disabled, .el-table .hidden-columns, .el-table td.is-hidden > *, .el-table th.is-hidden > *, .el-table--hidden {
  visibility: hidden;
}

.el-autocomplete-suggestion.is-loading li:after {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.el-dialog__header:after, .el-dialog__header:before {
  content: "";
  display: table;
}

@font-face {
  font-family: element-icons;
  src: url("element-icons.8780d738.woff") format("woff"), url("element-icons.9933b71f.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

[class*=" el-icon-"], [class^="el-icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  font-family: element-icons !important;
}

.el-icon-arrow-down:before {
  content: "";
}

.el-icon-arrow-left:before {
  content: "";
}

.el-icon-arrow-right:before {
  content: "";
}

.el-icon-arrow-up:before {
  content: "";
}

.el-icon-caret-bottom:before {
  content: "";
}

.el-icon-caret-left:before {
  content: "";
}

.el-icon-caret-right:before {
  content: "";
}

.el-icon-caret-top:before {
  content: "";
}

.el-icon-check:before {
  content: "";
}

.el-icon-circle-check:before {
  content: "";
}

.el-icon-circle-close:before {
  content: "";
}

.el-icon-circle-cross:before {
  content: "";
}

.el-icon-close:before {
  content: "";
}

.el-icon-upload:before {
  content: "";
}

.el-icon-d-arrow-left:before {
  content: "";
}

.el-icon-d-arrow-right:before {
  content: "";
}

.el-icon-d-caret:before {
  content: "";
}

.el-icon-date:before {
  content: "";
}

.el-icon-delete:before {
  content: "";
}

.el-icon-document:before {
  content: "";
}

.el-icon-edit:before {
  content: "";
}

.el-icon-information:before {
  content: "";
}

.el-icon-loading:before {
  content: "";
}

.el-icon-menu:before {
  content: "";
}

.el-icon-message:before {
  content: "";
}

.el-icon-minus:before {
  content: "";
}

.el-icon-more:before {
  content: "";
}

.el-icon-picture:before {
  content: "";
}

.el-icon-plus:before {
  content: "";
}

.el-icon-search:before {
  content: "";
}

.el-icon-setting:before {
  content: "";
}

.el-icon-share:before {
  content: "";
}

.el-icon-star-off:before {
  content: "";
}

.el-icon-star-on:before {
  content: "";
}

.el-icon-time:before {
  content: "";
}

.el-icon-warning:before {
  content: "";
}

.el-icon-delete2:before {
  content: "";
}

.el-icon-upload2:before {
  content: "";
}

.el-icon-view:before {
  content: "";
}

.el-icon-loading {
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.el-icon--right {
  margin-left: 5px;
}

.el-icon--left {
  margin-right: 5px;
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes rotating {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

.el-pagination {
  white-space: nowrap;
  color: #48576a;
  padding: 2px 5px;
}

.el-pagination:after, .el-pagination:before {
  content: "";
  display: table;
}

.el-pagination:after {
  clear: both;
}

.el-pagination button, .el-pagination span {
  min-width: 28px;
  height: 28px;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 28px;
  display: inline-block;
}

.el-pagination .el-select .el-input {
  width: 110px;
}

.el-pagination .el-select .el-input input {
  height: 28px;
  border-radius: 2px;
  padding-right: 25px;
}

.el-pagination button {
  background: none;
  border: none;
  padding: 0 6px;
}

.el-pagination button:focus {
  outline: 0;
}

.el-pagination button:hover {
  color: #20a0ff;
}

.el-pagination button.disabled {
  color: #e4e4e4;
  cursor: not-allowed;
  background-color: #fff;
}

.el-pager li, .el-pager li.btn-quicknext:hover, .el-pager li.btn-quickprev:hover {
  cursor: pointer;
}

.el-pagination .btn-next, .el-pagination .btn-prev {
  cursor: pointer;
  color: #97a8be;
  background: #fff center / 16px no-repeat;
  border: 1px solid #d1dbe5;
  margin: 0;
}

.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
  font-size: 12px;
  display: block;
}

.el-pagination .btn-prev {
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.el-pagination .btn-next {
  border-left: 0;
  border-radius: 0 2px 2px 0;
}

.el-pagination--small .btn-next, .el-pagination--small .btn-prev, .el-pagination--small .el-pager li, .el-pagination--small .el-pager li:last-child {
  height: 22px;
  min-width: 22px;
  border-color: rgba(0, 0, 0, 0);
  font-size: 12px;
  line-height: 22px;
}

.el-pagination--small .el-pager li {
  border-radius: 2px;
}

.el-pagination__sizes {
  margin: 0 10px 0 0;
}

.el-pagination__sizes .el-input .el-input__inner {
  border-color: #d1dbe5;
  font-size: 13px;
}

.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #20a0ff;
}

.el-pagination__jump {
  margin-left: 10px;
}

.el-pagination__total {
  margin: 0 10px;
}

.el-pagination__rightwrapper {
  float: right;
}

.el-pagination__editor {
  width: 30px;
  text-align: center;
  box-sizing: border-box;
  -moz-appearance: textfield;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 0 6px;
  padding: 4px 2px;
  line-height: 18px;
  transition: border .3s;
}

.el-pager, .el-pager li {
  vertical-align: top;
  margin: 0;
  display: inline-block;
}

.el-pagination__editor::-webkit-inner-spin-button, .el-pagination__editor::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-pagination__editor:focus {
  border-color: #20a0ff;
  outline: 0;
}

.el-autocomplete-suggestion__wrap, .el-pager li {
  box-sizing: border-box;
  border: 1px solid #d1dbe5;
}

.el-pager {
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.el-date-table, .el-radio {
  -webkit-user-select: none;
  -ms-user-select: none;
}

.el-date-table, .el-radio, .el-time-panel {
  -moz-user-select: none;
}

.el-pager li {
  min-width: 28px;
  height: 28px;
  text-align: center;
  background: #fff;
  border-right: 0;
  padding: 0 4px;
  font-size: 13px;
  line-height: 28px;
}

.el-pager li:last-child {
  border-right: 1px solid #d1dbe5;
}

.el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
  color: #97a8be;
  line-height: 28px;
}

.el-pager li.active + li {
  border-left: 0;
  padding-left: 5px;
}

.el-pager li:hover {
  color: #20a0ff;
}

.el-pager li.active {
  color: #fff;
  cursor: default;
  background-color: #20a0ff;
  border-color: #20a0ff;
}

.el-dialog {
  box-sizing: border-box;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 50px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
}

.el-dialog--tiny {
  width: 30%;
}

.el-dialog--small {
  width: 50%;
}

.el-dialog--large {
  width: 90%;
}

.el-dialog--full {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  top: 0;
  overflow: auto;
}

.el-dialog__wrapper {
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.el-autocomplete, .el-dropdown {
  display: inline-block;
  position: relative;
}

.el-dialog__header {
  padding: 20px 20px 0;
}

.el-dialog__headerbtn {
  float: right;
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  font-size: 16px;
}

.el-dialog__headerbtn .el-dialog__close {
  color: #bfcbd9;
}

.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: #20a0ff;
}

.el-dialog__title {
  color: #1f2d3d;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

.el-dialog__body {
  color: #48576a;
  padding: 30px 20px;
  font-size: 14px;
}

.el-dialog__footer {
  text-align: right;
  box-sizing: border-box;
  padding: 10px 20px 15px;
}

.dialog-fade-enter-active {
  -webkit-animation: dialog-fade-in .3s;
  animation: dialog-fade-in .3s;
}

.dialog-fade-leave-active {
  -webkit-animation: dialog-fade-out .3s;
  animation: dialog-fade-out .3s;
}

@-webkit-keyframes dialog-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes dialog-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes dialog-fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes dialog-fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

.el-autocomplete-suggestion {
  margin: 5px 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .12);
}

.el-autocomplete-suggestion li {
  cursor: pointer;
  color: #48576a;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  line-height: 36px;
  list-style: none;
  overflow: hidden;
}

.el-autocomplete-suggestion li:hover {
  background-color: #e4e8f1;
}

.el-autocomplete-suggestion li.highlighted {
  color: #fff;
  background-color: #20a0ff;
}

.el-autocomplete-suggestion li:active {
  background-color: #0082e6;
}

.el-autocomplete-suggestion.is-loading li:hover, .el-dropdown-menu {
  background-color: #fff;
}

.el-autocomplete-suggestion li.divider {
  border-top: 1px solid #d1dbe5;
  margin-top: 6px;
}

.el-autocomplete-suggestion li.divider:last-child {
  margin-bottom: -6px;
}

.el-autocomplete-suggestion.is-loading li {
  text-align: center;
  height: 100px;
  color: #999;
  font-size: 20px;
  line-height: 100px;
}

.el-autocomplete-suggestion.is-loading .el-icon-loading {
  vertical-align: middle;
}

.el-autocomplete-suggestion__wrap {
  max-height: 280px;
  background-color: #fff;
  border-radius: 2px;
  padding: 6px 0;
  overflow: auto;
}

.el-autocomplete-suggestion__list {
  margin: 0;
  padding: 0;
}

.el-dropdown {
  color: #48576a;
  font-size: 14px;
}

.el-dropdown .el-button-group {
  display: block;
}

.el-dropdown .el-button-group .el-button {
  float: none;
}

.el-dropdown .el-dropdown__caret-button {
  padding-left: 5px;
  padding-right: 5px;
}

.el-dropdown .el-dropdown__caret-button .el-dropdown__icon {
  padding-left: 0;
}

.el-dropdown__icon {
  margin: 0 3px;
  font-size: 12px;
}

.el-dropdown-menu {
  z-index: 10;
  min-width: 100px;
  border: 1px solid #d1dbe5;
  margin: 5px 0;
  padding: 6px 0;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .12);
}

.el-dropdown-menu__item {
  cursor: pointer;
  margin: 0;
  padding: 0 10px;
  line-height: 36px;
  list-style: none;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #48576a;
  background-color: #e4e8f1;
}

.el-dropdown-menu__item.is-disabled {
  cursor: default;
  color: #bfcbd9;
  pointer-events: none;
}

.el-dropdown-menu__item--divided {
  border-top: 1px solid #d1dbe5;
  margin-top: 6px;
  position: relative;
}

.el-dropdown-menu__item--divided:before {
  content: "";
  height: 6px;
  background-color: #fff;
  margin: 0 -10px;
  display: block;
}

.el-menu-item, .el-submenu__title {
  height: 56px;
  color: #48576a;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 0 20px;
  font-size: 14px;
  line-height: 56px;
  transition: border-color .3s, background-color .3s, color .3s;
  position: relative;
}

.el-menu {
  background-color: #eef1f6;
  border-radius: 2px;
  margin: 0;
  padding-left: 0;
  list-style: none;
  position: relative;
}

.el-menu:after, .el-menu:before {
  content: "";
  display: table;
}

.el-menu li {
  list-style: none;
}

.el-menu--dark {
  background-color: #324157;
}

.el-menu--dark .el-menu-item, .el-menu--dark .el-submenu__title {
  color: #bfcbd9;
}

.el-menu--dark .el-menu-item:hover, .el-menu--dark .el-submenu__title:hover {
  background-color: #48576a;
}

.el-menu--dark .el-submenu .el-menu {
  background-color: #1f2d3d;
}

.el-menu--dark .el-submenu .el-menu .el-menu-item:hover {
  background-color: #48576a;
}

.el-menu--horizontal .el-menu-item {
  float: left;
  height: 60px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  margin: 0;
  line-height: 60px;
  position: relative;
}

.el-menu--horizontal .el-menu-item a, .el-menu--horizontal .el-menu-item a:hover {
  color: inherit;
}

.el-menu--horizontal .el-submenu {
  float: left;
  position: relative;
}

.el-menu--horizontal .el-submenu > .el-menu {
  z-index: 100;
  min-width: 100%;
  background-color: #fff;
  border: 1px solid #d1dbe5;
  padding: 5px 0;
  position: absolute;
  top: 65px;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-menu--horizontal .el-submenu .el-submenu__title {
  height: 60px;
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  line-height: 60px;
}

.el-menu--horizontal .el-submenu .el-menu-item {
  float: none;
  height: 36px;
  background-color: #fff;
  padding: 0 10px;
  line-height: 36px;
}

.el-menu--horizontal .el-submenu .el-submenu__icon-arrow {
  vertical-align: middle;
  color: #97a8be;
  margin-top: -3px;
  margin-left: 5px;
  position: static;
}

.el-menu--horizontal .el-menu-item:hover, .el-menu--horizontal .el-submenu__title:hover {
  background-color: #eef1f6;
}

.el-menu--horizontal > .el-menu-item:hover, .el-menu--horizontal > .el-submenu.is-active .el-submenu__title, .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  border-bottom: 5px solid #20a0ff;
}

.el-menu--horizontal.el-menu--dark .el-menu-item:hover, .el-menu--horizontal.el-menu--dark .el-submenu__title:hover {
  background-color: #324157;
}

.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item:hover, .el-menu--horizontal.el-menu--dark .el-submenu .el-submenu-title:hover, .el-menu-item:hover {
  background-color: #d1dbe5;
}

.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item, .el-menu--horizontal.el-menu--dark .el-submenu .el-submenu-title {
  color: #48576a;
}

.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item.is-active, .el-menu-item.is-active {
  color: #20a0ff;
}

.el-menu--collapse {
  width: 64px;
}

.el-menu--collapse > .el-menu-item [class^="el-icon-"], .el-menu--collapse > .el-submenu > .el-submenu__title [class^="el-icon-"] {
  vertical-align: middle;
  width: 24px;
  text-align: center;
  margin: 0;
}

.el-menu--collapse > .el-menu-item .el-submenu__icon-arrow, .el-menu--collapse > .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-menu--collapse > .el-menu-item span, .el-menu--collapse > .el-submenu > .el-submenu__title span {
  height: 0;
  width: 0;
  visibility: hidden;
  display: inline-block;
  overflow: hidden;
}

.el-menu--collapse .el-menu .el-submenu {
  min-width: 200px;
}

.el-menu--collapse .el-submenu {
  position: relative;
}

.el-menu--collapse .el-submenu .el-menu {
  z-index: 10;
  margin-left: 5px;
  position: absolute;
  top: 0;
  left: 100%;
}

.el-menu--collapse .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: none;
  transform: none;
}

.el-menu-item [class^="el-icon-"] {
  width: 24px;
  text-align: center;
  margin-right: 5px;
}

.el-menu-item * {
  vertical-align: middle;
}

.el-menu-item:first-child {
  margin-left: 0;
}

.el-menu-item:last-child {
  margin-right: 0;
}

.el-submenu [class^="el-icon-"] {
  vertical-align: middle;
  width: 24px;
  text-align: center;
  margin-right: 5px;
}

.el-submenu .el-menu {
  background-color: #e4e8f1;
}

.el-submenu .el-menu-item:hover, .el-submenu__title:hover {
  background-color: #d1dbe5;
}

.el-submenu .el-menu-item {
  height: 50px;
  min-width: 200px;
  padding: 0 45px;
  line-height: 50px;
}

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #20a0ff;
}

.el-submenu__title {
  position: relative;
}

.el-submenu__title * {
  vertical-align: middle;
}

.el-submenu__icon-arrow {
  margin-top: -7px;
  font-size: 12px;
  transition: -webkit-transform .3s, transform .3s;
  position: absolute;
  top: 50%;
  right: 20px;
}

.el-radio, .el-radio__inner, .el-radio__input {
  display: inline-block;
  position: relative;
}

.el-menu-item-group > ul {
  padding: 0;
}

.el-menu-item-group__title {
  color: #97a8be;
  padding-top: 15px;
  padding-left: 20px;
  font-size: 14px;
  line-height: normal;
}

.el-radio-button__inner, .el-radio-group, .el-radio__input {
  vertical-align: middle;
  line-height: 1;
}

.horizontal-collapse-transition .el-submenu__title .el-submenu__icon-arrow {
  opacity: 0;
  transition: all .2s;
}

.el-radio {
  color: #1f2d3d;
  cursor: pointer;
  white-space: nowrap;
}

.el-radio + .el-radio {
  margin-left: 15px;
}

.el-radio__input {
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
}

.el-radio__input.is-focus .el-radio__inner {
  border-color: #20a0ff;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #20a0ff;
  border-color: #20a0ff;
}

.el-radio__input.is-checked .el-radio__inner:after {
  -webkit-transform: translate(-50%, -50%)scale(1);
  transform: translate(-50%, -50%)scale(1);
}

.el-radio__input.is-disabled .el-radio__inner {
  cursor: not-allowed;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.el-radio__input.is-disabled .el-radio__inner:after {
  cursor: not-allowed;
  background-color: #eef1f6;
}

.el-radio__input.is-disabled .el-radio__inner + .el-radio__label {
  cursor: not-allowed;
}

.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #d1dbe5;
  border-color: #d1dbe5;
}

.el-radio__inner, .el-radio__input.is-disabled.is-checked .el-radio__inner:after {
  background-color: #fff;
}

.el-radio__input.is-disabled + .el-radio__label {
  color: #bbb;
  cursor: not-allowed;
}

.el-radio__inner {
  width: 18px;
  height: 18px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #bfcbd9;
  border-radius: 50%;
}

.el-radio__inner:hover {
  border-color: #20a0ff;
}

.el-radio__inner:after {
  width: 6px;
  height: 6px;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: -webkit-transform .15s cubic-bezier(.71, -.46, .88, .6), transform .15s cubic-bezier(.71, -.46, .88, .6);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%)scale(0);
  transform: translate(-50%, -50%)scale(0);
}

.el-switch__core, .el-switch__label {
  width: 46px;
  height: 22px;
  cursor: pointer;
}

.el-radio__original {
  opacity: 0;
  z-index: -1;
  outline: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-radio-button, .el-radio-button__inner {
  display: inline-block;
  position: relative;
}

.el-radio__label {
  padding-left: 5px;
  font-size: 14px;
}

.el-radio-group {
  font-size: 0;
  display: inline-block;
}

.el-radio-group .el-radio {
  font-size: 14px;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #bfcbd9;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 4px 4px 0;
}

.el-radio-button:first-child:last-child .el-radio-button__inner {
  border-radius: 4px;
}

.el-radio-button__inner {
  white-space: nowrap;
  color: #1f2d3d;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #bfcbd9;
  border-left: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.el-radio-button__inner:hover {
  color: #20a0ff;
}

.el-radio-button__inner [class*="el-icon-"] {
  line-height: .9;
}

.el-radio-button__inner [class*="el-icon-"] + span {
  margin-left: 5px;
}

.el-radio-button__orig-radio {
  opacity: 0;
  z-index: -1;
  outline: 0;
  position: absolute;
  left: -999px;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  box-shadow: -1px 0 #20a0ff;
}

.el-radio-button__orig-radio:disabled + .el-radio-button__inner {
  color: #bfcbd9;
  cursor: not-allowed;
  box-shadow: none;
  background-color: #eef1f6;
  background-image: none;
  border-color: #d1dbe5;
}

.el-radio-button--large .el-radio-button__inner {
  border-radius: 0;
  padding: 11px 19px;
  font-size: 16px;
}

.el-radio-button--small .el-radio-button__inner {
  border-radius: 0;
  padding: 7px 9px;
  font-size: 12px;
}

.el-radio-button--mini .el-radio-button__inner {
  border-radius: 0;
  padding: 4px;
  font-size: 12px;
}

.el-switch, .el-switch__label, .el-switch__label * {
  font-size: 14px;
  display: inline-block;
}

.el-switch {
  height: 22px;
  vertical-align: middle;
  line-height: 22px;
  position: relative;
}

.el-switch .label-fade-enter, .el-switch .label-fade-leave-active {
  opacity: 0;
}

.el-switch.is-disabled .el-switch__core {
  background: #e4e8f1 !important;
  border-color: #e4e8f1 !important;
}

.el-switch.is-disabled .el-switch__core span {
  background-color: #fbfdff !important;
}

.el-switch.is-disabled .el-switch__core ~ .el-switch__label * {
  color: #fbfdff !important;
}

.el-switch.is-checked .el-switch__core {
  background-color: #20a0ff;
  border-color: #20a0ff;
}

.el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
  cursor: not-allowed;
}

.el-switch__label {
  z-index: 2;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.el-switch__label * {
  color: #fff;
  line-height: 1;
  position: absolute;
  top: 4px;
}

.el-switch__label--left i {
  left: 6px;
}

.el-switch__label--right i {
  right: 6px;
}

.el-switch__input {
  display: none;
}

.el-switch__input.allow-focus {
  z-index: 0;
  display: inline;
  display: initial;
  opacity: 0;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.el-switch__input.allow-focus:focus + .el-switch__core {
  box-shadow: 0 0 2px #20a0ff;
}

.el-switch__core {
  box-sizing: border-box;
  z-index: 1;
  background: #bfcbd9;
  border: 1px solid #bfcbd9;
  border-radius: 12px;
  outline: 0;
  margin: 0;
  transition: border-color .3s, background-color .3s;
  display: inline-block;
  position: relative;
}

.el-switch__core .el-switch__button {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 100%;
  transition: -webkit-transform .3s, transform .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.el-switch--wide .el-switch__label.el-switch__label--left span {
  left: 10px;
}

.el-switch--wide .el-switch__label.el-switch__label--right span {
  right: 10px;
}

.el-select-dropdown {
  z-index: 1001;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 5px 0;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-select-dropdown .el-scrollbar.is-empty .el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #20a0ff;
  background-color: #fff;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover, .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #e4e8f1;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:after {
  content: "";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: element-icons;
  font-size: 11px;
  position: absolute;
  right: 10px;
}

.el-select-dropdown__empty {
  text-align: center;
  color: #999;
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
}

.el-select-dropdown__wrap {
  max-height: 274px;
}

.el-select-dropdown__list {
  box-sizing: border-box;
  margin: 0;
  padding: 6px 0;
  list-style: none;
}

.el-select-dropdown__item {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #48576a;
  height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  overflow: hidden;
}

.el-select-dropdown__item.selected {
  color: #fff;
  background-color: #20a0ff;
}

.el-select-dropdown__item.selected.hover {
  background-color: #1c8de0;
}

.el-select-dropdown__item span {
  line-height: 1.5 !important;
}

.el-select-dropdown__item.is-disabled {
  color: #bfcbd9;
  cursor: not-allowed;
}

.el-select-dropdown__item.is-disabled:hover {
  background-color: #fff;
}

.el-select-group {
  margin: 0;
  padding: 0;
}

.el-select-group .el-select-dropdown__item {
  padding-left: 20px;
}

.el-select-group__wrap {
  margin: 0;
  padding: 0;
  list-style: none;
}

.el-select-group__title {
  color: #999;
  height: 30px;
  padding-left: 10px;
  font-size: 12px;
  line-height: 30px;
}

.el-select {
  display: inline-block;
  position: relative;
}

.el-select:hover .el-input__inner {
  border-color: #8391a5;
}

.el-select .el-input__inner {
  cursor: pointer;
  padding-right: 35px;
}

.el-select .el-input__inner:focus {
  border-color: #20a0ff;
}

.el-select .el-input .el-input__icon {
  color: #bfcbd9;
  -ms-transform: translateY(-50%)rotate(180deg);
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  transition: -webkit-transform .3s, transform .3s;
  top: 50%;
  -webkit-transform: translateY(-50%)rotateZ(180deg);
  transform: translateY(-50%)rotateZ(180deg);
}

.el-select .el-input .el-input__icon.is-show-close {
  width: 16px;
  height: 16px;
  text-align: center;
  -ms-transform: translateY(-50%)rotate(180deg);
  color: #bfcbd9;
  border-radius: 100%;
  font-size: 14px;
  transition: all;
  right: 8px;
  -webkit-transform: translateY(-50%)rotateZ(180deg);
  transform: translateY(-50%)rotateZ(180deg);
}

.el-select .el-input .el-input__icon.is-show-close:hover {
  color: #97a8be;
}

.el-select .el-input .el-input__icon.is-reverse {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
}

.el-select .el-input.is-disabled .el-input__inner:hover {
  border-color: #d1dbe5;
}

.el-select > .el-input {
  display: block;
}

.el-select .el-tag__close {
  margin-top: -2px;
}

.el-select .el-tag {
  height: 24px;
  box-sizing: border-box;
  margin: 3px 0 3px 6px;
  line-height: 24px;
}

.el-select__input {
  color: #666;
  vertical-align: baseline;
  -webkit-appearance: none;
  appearance: none;
  height: 28px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 14px;
}

.el-select__input.is-mini {
  height: 14px;
}

.el-select__close {
  cursor: pointer;
  z-index: 1000;
  color: #bfcbd9;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 8px;
  right: 25px;
}

.el-select__close:hover {
  color: #97a8be;
}

.el-select__tags {
  white-space: normal;
  z-index: 1;
  line-height: normal;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.el-table, .el-table td, .el-table th {
  box-sizing: border-box;
  position: relative;
}

.el-select__tag {
  height: 24px;
  color: #fff;
  background-color: #20a0ff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}

.el-select__tag .el-icon-close {
  font-size: 12px;
}

.el-table {
  width: 100%;
  max-width: 100%;
  color: #1f2d3d;
  background-color: #fff;
  border: 1px solid #dfe6ec;
  font-size: 14px;
  overflow: hidden;
}

.el-table .el-tooltip.cell {
  white-space: nowrap;
  min-width: 50px;
}

.el-table td, .el-table th {
  height: 40px;
  min-width: 0;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.el-table:after, .el-table:before {
  content: "";
  z-index: 1;
  background-color: #dfe6ec;
  position: absolute;
}

.el-table td.is-right, .el-table th.is-right {
  text-align: right;
}

.el-table td.is-left, .el-table th.is-left {
  text-align: left;
}

.el-table td.is-center, .el-table th.is-center {
  text-align: center;
}

.el-table td, .el-table th.is-leaf {
  border-bottom: 1px solid #dfe6ec;
}

.el-table td.gutter, .el-table th.gutter {
  width: 15px;
  border-bottom-width: 0;
  border-right-width: 0;
  padding: 0;
}

.el-table .cell, .el-table th > div {
  box-sizing: border-box;
  text-overflow: ellipsis;
  padding-left: 18px;
  padding-right: 18px;
}

.el-table:before {
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

.el-table:after {
  width: 1px;
  height: 100%;
  top: 0;
  right: 0;
}

.el-table .caret-wrapper, .el-table th > .cell {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.el-table th {
  white-space: nowrap;
  text-align: left;
  background-color: #eef1f6;
  overflow: hidden;
}

.el-table th.is-sortable {
  cursor: pointer;
}

.el-table th > div {
  white-space: nowrap;
  line-height: 40px;
  display: inline-block;
  overflow: hidden;
}

.el-table td > div {
  box-sizing: border-box;
}

.el-table th.required > div:before {
  content: "";
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background: #ff4d51;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.el-table th > .cell {
  word-wrap: normal;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
  line-height: 30px;
}

.el-table th > .cell.highlight {
  color: #20a0ff;
}

.el-table .caret-wrapper {
  cursor: pointer;
  width: 16px;
  height: 30px;
  overflow: visible;
  overflow: initial;
  margin-top: -2px;
  margin-left: 5px;
}

.el-table .cell, .el-table__footer-wrapper, .el-table__header-wrapper {
  overflow: hidden;
}

.el-table .sort-caret {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border: 0;
  display: inline-block;
  position: absolute;
  left: 3px;
}

.el-table .sort-caret.ascending, .el-table .sort-caret.descending {
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
}

.el-table .sort-caret.ascending {
  border-top: none;
  border-bottom: 5px solid #97a8be;
  top: 9px;
}

.el-table .sort-caret.descending {
  border-top: 5px solid #97a8be;
  border-bottom: none;
  bottom: 9px;
}

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #48576a;
}

.el-table .descending .sort-caret.descending {
  border-top-color: #48576a;
}

.el-table td.gutter {
  width: 0;
}

.el-table .cell {
  white-space: normal;
  word-break: break-all;
  line-height: 24px;
}

.el-badge__content, .el-message__group p, .el-progress-bar__inner, .el-steps.is-horizontal, .el-tabs__nav, .el-tag, .el-time-spinner, .el-tree-node, .el-upload-list__item-name {
  white-space: nowrap;
}

.el-table tr input[type="checkbox"] {
  margin: 0;
}

.el-table tr {
  background-color: #fff;
}

.el-table .hidden-columns {
  z-index: -1;
  position: absolute;
}

.el-table__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.el-table__empty-text {
  color: #5e7382;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.el-table__expand-column .cell {
  text-align: center;
  padding: 0;
}

.el-table__expand-icon {
  cursor: pointer;
  color: #666;
  height: 40px;
  font-size: 12px;
  transition: -webkit-transform .2s ease-in-out, transform .2s ease-in-out;
  position: relative;
}

.el-table__expand-icon > .el-icon {
  margin-top: -5px;
  margin-left: -5px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.el-table__expand-icon--expanded {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.el-table__expanded-cell {
  background-color: #fbfdff;
  padding: 20px 50px;
  box-shadow: inset 0 2px #f4f4f4;
}

.el-table__expanded-cell:hover {
  background-color: #fbfdff !important;
}

.el-table--fit {
  border-bottom: 0;
  border-right: 0;
}

.el-table--border th, .el-table__fixed-right-patch {
  border-bottom: 1px solid #dfe6ec;
}

.el-table--fit td.gutter, .el-table--fit th.gutter {
  border-right-width: 1px;
}

.el-table--border td, .el-table--border th {
  border-right: 1px solid #dfe6ec;
}

.el-table__fixed, .el-table__fixed-right {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  box-shadow: 1px 0 8px #d3d4d6;
}

.el-table__fixed-right:before, .el-table__fixed:before {
  content: "";
  width: 100%;
  height: 1px;
  z-index: 4;
  background-color: #dfe6ec;
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-table__fixed-right-patch {
  background-color: #eef1f6;
  position: absolute;
  top: -1px;
  right: 0;
}

.el-table__fixed-right {
  top: 0;
  left: auto;
  right: 0;
  box-shadow: -1px 0 8px #d3d4d6;
}

.el-table__fixed-right .el-table__fixed-body-wrapper, .el-table__fixed-right .el-table__fixed-footer-wrapper, .el-table__fixed-right .el-table__fixed-header-wrapper {
  left: auto;
  right: 0;
}

.el-table__fixed-header-wrapper {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.el-table__fixed-header-wrapper thead div {
  color: #1f2d3d;
  background-color: #eef1f6;
}

.el-table__fixed-footer-wrapper {
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-table__fixed-footer-wrapper tbody td {
  color: #1f2d3d;
  background-color: #fbfdff;
  border-top: 1px solid #dfe6ec;
}

.el-table__fixed-body-wrapper {
  z-index: 3;
  position: absolute;
  top: 37px;
  left: 0;
  overflow: hidden;
}

.el-table__body-wrapper, .el-table__footer-wrapper, .el-table__header-wrapper {
  width: 100%;
}

.el-table__footer-wrapper {
  margin-top: -1px;
}

.el-table__footer-wrapper td {
  border-top: 1px solid #dfe6ec;
}

.el-table__body, .el-table__footer, .el-table__header {
  table-layout: fixed;
}

.el-table__footer-wrapper thead div, .el-table__header-wrapper thead div {
  color: #1f2d3d;
  background-color: #eef1f6;
}

.el-table__footer-wrapper tbody td, .el-table__header-wrapper tbody td {
  color: #1f2d3d;
  background-color: #fbfdff;
}

.el-table__body-wrapper {
  position: relative;
  overflow: auto;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #fafafa padding-box padding-box;
}

.el-table--striped .el-table__body tr.el-table__row--striped.current-row td {
  background: #edf7ff;
}

.el-table__body tr.hover-row.current-row > td, .el-table__body tr.hover-row.el-table__row--striped.current-row > td, .el-table__body tr.hover-row.el-table__row--striped > td, .el-table__body tr.hover-row > td {
  background-color: #eef1f6;
}

.el-table__body tr.current-row > td {
  background: #edf7ff;
}

.el-table__column-resize-proxy {
  width: 0;
  z-index: 10;
  border-left: 1px solid #dfe6ec;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
}

.el-table__column-filter-trigger {
  cursor: pointer;
  margin-left: 5px;
  line-height: 34px;
  display: inline-block;
}

.el-table__column-filter-trigger i {
  color: #97a8be;
}

.el-table--enable-row-transition .el-table__body td {
  transition: background-color .25s;
}

.el-fade-in-linear-enter-active, .el-fade-in-linear-leave-active, .fade-in-linear-enter-active, .fade-in-linear-leave-active {
  transition: opacity .2s linear;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #eef1f6;
  background-clip: padding-box;
}

.el-table--fluid-height .el-table__fixed, .el-table--fluid-height .el-table__fixed-right {
  bottom: 0;
  overflow: hidden;
}

.el-table-column--selection .cell {
  padding-left: 14px;
  padding-right: 14px;
}

.el-table-filter {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 2px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .12);
}

.el-table-filter__list {
  min-width: 100px;
  margin: 0;
  padding: 5px 0;
  list-style: none;
}

.el-table-filter__list-item {
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
  line-height: 36px;
}

.el-table-filter__list-item:hover {
  color: #48576a;
  background-color: #e4e8f1;
}

.el-table-filter__list-item.is-active {
  color: #fff;
  background-color: #20a0ff;
}

.el-table-filter__content {
  min-width: 100px;
}

.el-table-filter__bottom {
  border-top: 1px solid #d1dbe5;
  padding: 8px;
}

.el-table-filter__bottom button {
  color: #8391a5;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0 3px;
  font-size: 14px;
}

.el-table-filter__bottom button:hover {
  color: #20a0ff;
}

.el-table-filter__bottom button:focus {
  outline: 0;
}

.el-table-filter__bottom button.is-disabled {
  color: #bfcbd9;
  cursor: not-allowed;
}

.el-table-filter__checkbox-group {
  padding: 10px;
}

.el-table-filter__checkbox-group label.el-checkbox {
  margin-bottom: 8px;
  margin-left: 5px;
  display: block;
}

.el-table-filter__checkbox-group .el-checkbox:last-child {
  margin-bottom: 0;
}

.el-date-table {
  min-width: 224px;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
}

.el-date-table td {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.el-date-table td.next-month, .el-date-table td.prev-month {
  color: #ddd;
}

.el-date-table td.today {
  color: #20a0ff;
  position: relative;
}

.el-date-table td.today:before {
  content: " ";
  width: 0;
  height: 0;
  border-top: .5em solid #20a0ff;
  border-left: .5em solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  right: 0;
}

.el-month-table td .cell, .el-year-table td .cell {
  width: 48px;
  height: 32px;
  line-height: 32px;
  display: block;
}

.el-date-table td.available:hover {
  background-color: #e4e8f1;
}

.el-date-table td.in-range {
  background-color: #d2ecff;
}

.el-date-table td.in-range:hover {
  background-color: #afddff;
}

.el-date-table td.current:not(.disabled), .el-date-table td.end-date, .el-date-table td.start-date {
  color: #fff;
  background-color: #20a0ff !important;
}

.el-date-table td.disabled {
  opacity: 1;
  cursor: not-allowed;
  color: #ccc;
  background-color: #f4f4f4;
}

.el-fade-in-enter, .el-fade-in-leave-active, .el-fade-in-linear-enter, .el-fade-in-linear-leave, .el-fade-in-linear-leave-active, .fade-in-linear-enter, .fade-in-linear-leave, .fade-in-linear-leave-active {
  opacity: 0;
}

.el-date-table td.week {
  color: #8391a5;
  font-size: 80%;
}

.el-month-table, .el-year-table {
  border-collapse: collapse;
  margin: -1px;
  font-size: 12px;
}

.el-date-table th {
  color: #8391a5;
  padding: 5px;
  font-weight: 400;
}

.el-date-table.is-week-mode .el-date-table__row:hover {
  background-color: #e4e8f1;
}

.el-date-table.is-week-mode .el-date-table__row.current {
  background-color: #d2ecff;
}

.el-month-table td {
  text-align: center;
  cursor: pointer;
  padding: 20px 3px;
}

.el-month-table td .cell {
  color: #48576a;
}

.el-month-table td .cell:hover {
  background-color: #e4e8f1;
}

.el-month-table td.disabled .cell {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f4f4f4;
}

.el-month-table td.current:not(.disabled) .cell {
  color: #fff;
  background-color: #20a0ff !important;
}

.el-year-table .el-icon {
  color: #97a8be;
}

.el-year-table td {
  text-align: center;
  cursor: pointer;
  padding: 20px 3px;
}

.el-year-table td .cell {
  color: #48576a;
}

.el-year-table td .cell:hover {
  background-color: #e4e8f1;
}

.el-year-table td.disabled .cell {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f4f4f4;
}

.el-year-table td.current:not(.disabled) .cell {
  color: #fff;
  background-color: #20a0ff !important;
}

.el-date-range-picker {
  min-width: 520px;
}

.el-date-range-picker table {
  table-layout: fixed;
  width: 100%;
}

.el-date-range-picker .el-picker-panel__body {
  min-width: 513px;
}

.el-date-range-picker .el-picker-panel__content {
  margin: 0;
}

.el-date-range-picker.has-sidebar.has-time {
  min-width: 766px;
}

.el-date-range-picker.has-sidebar {
  min-width: 620px;
}

.el-date-range-picker.has-time {
  min-width: 660px;
}

.el-date-range-picker__header {
  text-align: center;
  height: 28px;
  position: relative;
}

.el-date-range-picker__header button {
  float: left;
}

.el-date-range-picker__header div {
  margin-right: 50px;
  font-size: 14px;
}

.el-date-range-picker__content {
  float: left;
  width: 50%;
  box-sizing: border-box;
  margin: 0;
  padding: 16px;
}

.el-date-range-picker__content.is-right .el-date-range-picker__header button {
  float: right;
}

.el-date-range-picker__content.is-right .el-date-range-picker__header div {
  margin-left: 50px;
  margin-right: 50px;
}

.el-date-range-picker__content.is-left {
  border-right: 1px solid #e4e4e4;
}

.el-date-range-picker__editors-wrap {
  box-sizing: border-box;
  display: table-cell;
}

.el-date-range-picker__editors-wrap.is-right {
  text-align: right;
}

.el-date-range-picker__time-header {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 5px 5px;
  font-size: 12px;
  display: table;
  position: relative;
}

.el-date-range-picker__time-header > .el-icon-arrow-right {
  vertical-align: middle;
  color: #97a8be;
  font-size: 20px;
  display: table-cell;
}

.el-date-range-picker__time-picker-wrap {
  padding: 0 5px;
  display: table-cell;
  position: relative;
}

.el-date-range-picker__time-picker-wrap .el-picker-panel {
  z-index: 1;
  background: #fff;
  position: absolute;
  top: 13px;
  right: 0;
}

.el-time-range-picker {
  min-width: 354px;
  overflow: visible;
}

.el-time-range-picker__content {
  text-align: center;
  padding: 10px;
  position: relative;
}

.el-time-range-picker__cell {
  box-sizing: border-box;
  width: 50%;
  margin: 0;
  padding: 4px 7px 7px;
  display: inline-block;
}

.el-time-range-picker__header {
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.el-time-range-picker__body {
  border: 1px solid #d1dbe5;
  border-radius: 2px;
}

.el-picker-panel {
  color: #48576a;
  background: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 5px 0;
  line-height: 20px;
  box-shadow: 0 2px 6px #ccc;
}

.el-picker-panel__body-wrapper:after, .el-picker-panel__body:after {
  content: "";
  display: table;
}

.el-picker-panel__content {
  margin: 15px;
  position: relative;
}

.el-picker-panel__footer {
  text-align: right;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
  padding: 4px;
  position: relative;
}

.el-picker-panel__shortcut {
  width: 100%;
  color: #48576a;
  text-align: left;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding-left: 12px;
  font-size: 14px;
  line-height: 28px;
  display: block;
}

.el-picker-panel__shortcut:hover {
  background-color: #e4e8f1;
}

.el-picker-panel__shortcut.active {
  color: #20a0ff;
  background-color: #e6f1fe;
}

.el-picker-panel__btn {
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  outline: 0;
  padding: 0 20px;
  font-size: 12px;
  line-height: 24px;
}

.el-picker-panel__btn[disabled] {
  color: #ccc;
  cursor: not-allowed;
}

.el-picker-panel__icon-btn {
  color: #97a8be;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin-top: 3px;
  font-size: 12px;
}

.el-date-picker__header-label.active, .el-date-picker__header-label:hover, .el-picker-panel__icon-btn:hover {
  color: #20a0ff;
}

.el-picker-panel__link-btn {
  cursor: pointer;
  color: #20a0ff;
  padding: 15px;
  font-size: 12px;
  text-decoration: none;
}

.el-picker-panel [slot="sidebar"], .el-picker-panel__sidebar {
  width: 110px;
  box-sizing: border-box;
  background-color: #fbfdff;
  border-right: 1px solid #e4e4e4;
  padding-top: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.el-picker-panel [slot="sidebar"] + .el-picker-panel__body, .el-picker-panel__sidebar + .el-picker-panel__body {
  margin-left: 110px;
}

.el-date-picker {
  min-width: 254px;
}

.el-date-picker .el-picker-panel__content {
  min-width: 224px;
}

.el-date-picker table {
  table-layout: fixed;
  width: 100%;
}

.el-date-picker.has-sidebar.has-time {
  min-width: 434px;
}

.el-date-picker.has-sidebar {
  min-width: 370px;
}

.el-date-picker.has-time {
  min-width: 324px;
}

.el-date-picker.has-time .el-picker-panel__body-wrapper {
  position: relative;
}

.el-date-picker__editor-wrap {
  padding: 0 5px;
  display: table-cell;
  position: relative;
}

.el-date-picker__time-header {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 5px 5px;
  font-size: 12px;
  display: table;
  position: relative;
}

.el-date-picker__header {
  text-align: center;
  margin: 12px;
}

.el-date-picker__header-label {
  text-align: center;
  cursor: pointer;
  padding: 0 5px;
  font-size: 14px;
  line-height: 22px;
}

.el-date-picker__prev-btn {
  float: left;
}

.el-date-picker__next-btn {
  float: right;
}

.el-date-picker__time-wrap {
  text-align: center;
  padding: 10px;
}

.el-date-picker__time-label {
  float: left;
  cursor: pointer;
  margin-left: 10px;
  line-height: 30px;
}

.time-select {
  min-width: 0;
  margin: 5px 0;
}

.time-select .el-picker-panel__content {
  max-height: 200px;
  margin: 0;
}

.time-select-item {
  padding: 8px 10px;
  font-size: 14px;
}

.time-select-item.selected:not(.disabled) {
  color: #fff;
  background-color: #20a0ff;
}

.time-select-item.selected:not(.disabled):hover {
  background-color: #20a0ff;
}

.time-select-item.disabled {
  color: #d1dbe5;
  cursor: not-allowed;
}

.time-select-item:hover {
  cursor: pointer;
  background-color: #e4e8f1;
}

.el-fade-in-enter-active, .el-fade-in-leave-active, .el-zoom-in-center-enter-active, .el-zoom-in-center-leave-active {
  transition: all .3s cubic-bezier(.55, 0, .1, 1);
}

.el-zoom-in-bottom-enter-active, .el-zoom-in-bottom-leave-active, .el-zoom-in-left-enter-active, .el-zoom-in-left-leave-active, .el-zoom-in-top-enter-active, .el-zoom-in-top-leave-active {
  transition: -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .1s, transform .3s cubic-bezier(.23, 1, .32, 1) .1s, opacity .3s cubic-bezier(.23, 1, .32, 1) .1s;
}

.el-zoom-in-center-enter, .el-zoom-in-center-leave-active {
  opacity: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.el-zoom-in-top-enter-active, .el-zoom-in-top-leave-active {
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.el-zoom-in-top-enter, .el-zoom-in-top-leave-active {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.el-zoom-in-bottom-enter-active, .el-zoom-in-bottom-leave-active {
  opacity: 1;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.el-zoom-in-bottom-enter, .el-zoom-in-bottom-leave-active {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.el-zoom-in-left-enter-active, .el-zoom-in-left-leave-active {
  opacity: 1;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.el-zoom-in-left-enter, .el-zoom-in-left-leave-active {
  opacity: 0;
  -webkit-transform: scale(.45);
  transform: scale(.45);
}

.collapse-transition {
  transition: height .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out;
}

.horizontal-collapse-transition {
  transition: width .3s ease-in-out, padding-left .3s ease-in-out, padding-right .3s ease-in-out;
}

.el-list-enter-active, .el-list-leave-active {
  transition: all 1s;
}

.el-list-enter, .el-list-leave-active {
  opacity: 0;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.el-opacity-transition {
  transition: opacity .3s cubic-bezier(.55, 0, .1, 1);
}

.el-date-editor {
  display: inline-block;
  position: relative;
}

.el-date-editor .el-picker-panel {
  min-width: 180px;
  box-sizing: border-box;
  z-index: 10;
  background: #fff;
  position: absolute;
  top: 41px;
  box-shadow: 0 2px 6px #ccc;
}

.el-date-editor.el-input {
  width: 193px;
}

.el-date-editor--daterange.el-input {
  width: 220px;
}

.el-date-editor--datetimerange.el-input {
  width: 350px;
}

.el-time-spinner.has-seconds .el-time-spinner__wrapper {
  width: 33%;
}

.el-time-spinner.has-seconds .el-time-spinner__wrapper:nth-child(2) {
  margin-left: 1%;
}

.el-time-spinner__wrapper {
  max-height: 190px;
  width: 50%;
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: auto;
}

.el-time-spinner__wrapper .el-scrollbar__wrap:not(.el-scrollbar__wrap--hidden-default) {
  padding-bottom: 15px;
}

.el-time-spinner__list {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.el-time-spinner__list:after, .el-time-spinner__list:before {
  content: "";
  width: 100%;
  height: 80px;
  display: block;
}

.el-time-spinner__item {
  height: 32px;
  font-size: 12px;
  line-height: 32px;
}

.el-time-spinner__item:hover:not(.disabled):not(.active) {
  cursor: pointer;
  background: #e4e8f1;
}

.el-time-spinner__item.active:not(.disabled) {
  color: #fff;
}

.el-time-spinner__item.disabled {
  color: #d1dbe5;
  cursor: not-allowed;
}

.el-time-panel {
  width: 180px;
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 5px 0;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-popover, .el-tabs--border-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-slider__button, .el-slider__button-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
}

.el-time-panel__content {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.el-time-panel__content:after, .el-time-panel__content:before {
  content: ":";
  color: #fff;
  height: 32px;
  z-index: -1;
  box-sizing: border-box;
  text-align: left;
  background-color: #20a0ff;
  margin-top: -15px;
  padding-top: 6px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.el-time-panel__content:after {
  margin-left: -2px;
  left: 50%;
}

.el-time-panel__content:before {
  margin-right: -2px;
  padding-left: 50%;
}

.el-time-panel__content.has-seconds:after {
  left: 66.6667%;
}

.el-time-panel__content.has-seconds:before {
  padding-left: 33.3333%;
}

.el-time-panel__footer {
  height: 36px;
  text-align: right;
  box-sizing: border-box;
  border-top: 1px solid #e4e4e4;
  padding: 4px;
  line-height: 25px;
}

.el-time-panel__btn {
  cursor: pointer;
  color: #8391a5;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: 0;
  margin: 0 5px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 28px;
}

.el-time-panel__btn.confirm {
  color: #20a0ff;
  font-weight: 800;
}

.el-popover {
  min-width: 150px;
  z-index: 2000;
  background: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  padding: 10px;
  font-size: 12px;
  position: absolute;
}

.el-popover .popper__arrow, .el-popover .popper__arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.el-popover .popper__arrow {
  border-width: 6px;
}

.el-popover .popper__arrow:after {
  content: " ";
  border-width: 6px;
}

.el-popover[x-placement^="top"] {
  margin-bottom: 12px;
}

.el-popover[x-placement^="top"] .popper__arrow {
  border-top-color: #d1dbe5;
  border-bottom-width: 0;
  margin-right: 3px;
  bottom: -6px;
  left: 50%;
}

.el-popover[x-placement^="top"] .popper__arrow:after {
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -6px;
  bottom: 1px;
}

.el-popover[x-placement^="bottom"] {
  margin-top: 12px;
}

.el-popover[x-placement^="bottom"] .popper__arrow {
  border-top-width: 0;
  border-bottom-color: #d1dbe5;
  margin-right: 3px;
  top: -6px;
  left: 50%;
}

.el-popover[x-placement^="bottom"] .popper__arrow:after {
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -6px;
  top: 1px;
}

.el-popover[x-placement^="right"] {
  margin-left: 12px;
}

.el-popover[x-placement^="right"] .popper__arrow {
  border-left-width: 0;
  border-right-color: #d1dbe5;
  margin-bottom: 3px;
  top: 50%;
  left: -6px;
}

.el-popover[x-placement^="right"] .popper__arrow:after {
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -6px;
  left: 1px;
}

.el-popover[x-placement^="left"] {
  margin-right: 12px;
}

.el-popover[x-placement^="left"] .popper__arrow {
  border-left-color: #d1dbe5;
  border-right-width: 0;
  margin-bottom: 3px;
  top: 50%;
  right: -6px;
}

.el-popover[x-placement^="left"] .popper__arrow:after {
  border-left-color: #fff;
  border-right-width: 0;
  margin-left: -6px;
  bottom: -6px;
  right: 1px;
}

.el-popover__title {
  color: #1f2d3d;
  margin-bottom: 9px;
  font-size: 13px;
  line-height: 1;
}

.v-modal-enter {
  -webkit-animation: v-modal-in .2s;
  animation: v-modal-in .2s;
}

.v-modal-leave {
  -webkit-animation: v-modal-out .2s forwards;
  animation: v-modal-out .2s forwards;
}

@-webkit-keyframes v-modal-in {
  0% {
    opacity: 0;
  }
}

@keyframes v-modal-in {
  0% {
    opacity: 0;
  }
}

@-webkit-keyframes v-modal-out {
  100% {
    opacity: 0;
  }
}

@keyframes v-modal-out {
  100% {
    opacity: 0;
  }
}

.v-modal {
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.el-message-box {
  text-align: left;
  vertical-align: middle;
  width: 420px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 3px;
  font-size: 16px;
  display: inline-block;
  overflow: hidden;
}

.el-message-box__wrapper {
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-message-box__wrapper:after {
  content: "";
  height: 100%;
  width: 0;
  vertical-align: middle;
  display: inline-block;
}

.el-message-box__header {
  padding: 20px 20px 0;
  position: relative;
}

.el-message-box__headerbtn {
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 19px;
  right: 20px;
}

.el-message-box__headerbtn .el-message-box__close {
  color: #999;
}

.el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close {
  color: #20a0ff;
}

.el-message-box__content {
  color: #48576a;
  padding: 30px 20px;
  font-size: 14px;
  position: relative;
}

.el-message-box__input {
  padding-top: 15px;
}

.el-message-box__input input.invalid, .el-message-box__input input.invalid:focus {
  border-color: #ff4949;
}

.el-message-box__errormsg {
  color: #ff4949;
  min-height: 18px;
  margin-top: 2px;
  font-size: 12px;
}

.el-message-box__title {
  height: 18px;
  color: #333;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
}

.el-message-box__message {
  margin: 0;
}

.el-message-box__message p {
  margin: 0;
  line-height: 1.4;
}

.el-message-box__btns {
  text-align: right;
  padding: 10px 20px 15px;
}

.el-message-box__btns button:nth-child(2) {
  margin-left: 10px;
}

.el-message-box__btns-reverse {
  flex-direction: row-reverse;
}

.el-message-box__status {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 36px !important;
}

.el-message-box__status.el-icon-circle-check {
  color: #13ce66;
}

.el-message-box__status.el-icon-information {
  color: #50bfff;
}

.el-message-box__status.el-icon-warning {
  color: #f7ba2a;
}

.el-message-box__status.el-icon-circle-cross {
  color: #ff4949;
}

.msgbox-fade-enter-active {
  -webkit-animation: msgbox-fade-in .3s;
  animation: msgbox-fade-in .3s;
}

.msgbox-fade-leave-active {
  -webkit-animation: msgbox-fade-out .3s;
  animation: msgbox-fade-out .3s;
}

@-webkit-keyframes msgbox-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes msgbox-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes msgbox-fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes msgbox-fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

.el-breadcrumb {
  font-size: 13px;
  line-height: 1;
}

.el-breadcrumb__separator {
  color: #bfcbd9;
  margin: 0 8px;
}

.el-breadcrumb__item {
  float: left;
}

.el-breadcrumb__item:last-child .el-breadcrumb__item__inner, .el-breadcrumb__item:last-child .el-breadcrumb__item__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__item__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__item__inner:hover {
  color: #97a8be;
  cursor: text;
}

.el-breadcrumb__item:last-child .el-breadcrumb__separator {
  display: none;
}

.el-breadcrumb__item__inner, .el-breadcrumb__item__inner a {
  color: #48576a;
  transition: color .15s linear;
}

.el-breadcrumb__item__inner a:hover, .el-breadcrumb__item__inner:hover {
  color: #20a0ff;
  cursor: pointer;
}

.el-form--label-left .el-form-item__label {
  text-align: left;
}

.el-form--label-top .el-form-item__label {
  float: none;
  text-align: left;
  padding: 0 0 10px;
  display: inline-block;
}

.el-form--inline .el-form-item {
  vertical-align: top;
  margin-right: 10px;
  display: inline-block;
}

.el-form--inline .el-form-item__label {
  float: none;
  display: inline-block;
}

.el-form--inline .el-form-item__content {
  vertical-align: top;
  display: inline-block;
}

.el-form--inline.el-form--label-top .el-form-item__content {
  display: block;
}

.el-form-item {
  margin-bottom: 22px;
}

.el-form-item .el-form-item {
  margin-bottom: 0;
}

.el-form-item.is-error .el-input-group__append .el-input__inner, .el-form-item.is-error .el-input-group__prepend .el-input__inner, .el-form-item.is-error .el-input__inner {
  border-color: rgba(0, 0, 0, 0);
}

.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-textarea__inner {
  border-color: #ff4949;
}

.el-form-item.is-required .el-form-item__label:before {
  content: "*";
  color: #ff4949;
  margin-right: 4px;
}

.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  color: #48576a;
  box-sizing: border-box;
  padding: 11px 12px 11px 0;
  font-size: 14px;
  line-height: 1;
}

.el-form-item__content {
  font-size: 14px;
  line-height: 36px;
  position: relative;
}

.el-form-item__error {
  color: #ff4949;
  padding-top: 4px;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-tabs__header {
  border-bottom: 1px solid #d1dbe5;
  margin: 0 0 15px;
  padding: 0;
  position: relative;
}

.el-tabs__active-bar {
  height: 3px;
  z-index: 1;
  background-color: #20a0ff;
  list-style: none;
  transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-tabs__new-tab {
  float: right;
  height: 18px;
  width: 18px;
  text-align: center;
  color: #d3dce6;
  cursor: pointer;
  border: 1px solid #d3dce6;
  border-radius: 3px;
  margin: 12px 0 9px 10px;
  font-size: 12px;
  line-height: 18px;
  transition: all .15s;
}

.el-tabs__new-tab .el-icon-plus {
  -webkit-transform: scale(.8);
  transform: scale(.8);
}

.el-tabs__new-tab:hover {
  color: #20a0ff;
}

.el-tabs__nav-wrap {
  margin-bottom: -1px;
  position: relative;
  overflow: hidden;
}

.el-tabs__nav-wrap.is-scrollable {
  padding: 0 15px;
}

.el-tabs__nav-scroll {
  overflow: hidden;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
  cursor: pointer;
  color: #8391a5;
  font-size: 12px;
  line-height: 44px;
  position: absolute;
}

.el-tabs__nav-next {
  right: 0;
}

.el-tabs__nav-prev {
  left: 0;
}

.el-tabs__nav {
  float: left;
  transition: -webkit-transform .3s, transform .3s;
  position: relative;
}

.el-tabs__item {
  height: 42px;
  box-sizing: border-box;
  color: #8391a5;
  padding: 0 16px;
  font-size: 14px;
  line-height: 42px;
  list-style: none;
  display: inline-block;
  position: relative;
}

.el-tabs__item .el-icon-close {
  text-align: center;
  border-radius: 50%;
  margin-left: 5px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.el-tabs__item .el-icon-close:before {
  display: inline-block;
  -webkit-transform: scale(.7);
  transform: scale(.7);
}

.el-tabs__item .el-icon-close:hover {
  color: #fff;
  background-color: #97a8be;
}

.el-tabs__item:hover {
  color: #1f2d3d;
  cursor: pointer;
}

.el-tabs__item.is-disabled {
  color: #bbb;
  cursor: default;
}

.el-tabs__item.is-active {
  color: #20a0ff;
}

.el-tabs__content {
  position: relative;
  overflow: hidden;
}

.el-tabs--card > .el-tabs__header .el-tabs__active-bar {
  display: none;
}

.el-tag, .slideInLeft-transition, .slideInRight-transition {
  display: inline-block;
}

.el-tabs--card > .el-tabs__header .el-tabs__item .el-icon-close {
  width: 0;
  height: 14px;
  vertical-align: middle;
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  top: -1px;
  right: -2px;
  overflow: hidden;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable .el-icon-close, .el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover .el-icon-close {
  width: 14px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover {
  padding-left: 9px;
  padding-right: 9px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: 1px solid #d1dbe5;
  border-bottom-color: #fff;
  border-radius: 4px 4px 0 0;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  padding-left: 16px;
  padding-right: 16px;
}

.el-tabs--border-card {
  background: #fff;
  border: 1px solid #d1dbe5;
}

.el-tabs--border-card > .el-tabs__content {
  padding: 15px;
}

.el-tabs--border-card > .el-tabs__header {
  background-color: #eef1f6;
  margin: 0;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: 1px solid rgba(0, 0, 0, 0);
  border-top: 0;
  margin-left: -1px;
  margin-right: -1px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #fff;
  border-left-color: #d1dbe5;
  border-right-color: #d1dbe5;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active:first-child {
  border-left-color: #d1dbe5;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active:last-child {
  border-right-color: #d1dbe5;
}

.slideInRight-enter {
  -webkit-animation: slideInRight-enter .3s;
  animation: slideInRight-enter .3s;
}

.slideInRight-leave {
  -webkit-animation: slideInRight-leave .3s;
  animation: slideInRight-leave .3s;
  position: absolute;
  left: 0;
  right: 0;
}

.slideInLeft-enter {
  -webkit-animation: slideInLeft-enter .3s;
  animation: slideInLeft-enter .3s;
}

.slideInLeft-leave {
  -webkit-animation: slideInLeft-leave .3s;
  animation: slideInLeft-leave .3s;
  position: absolute;
  left: 0;
  right: 0;
}

@-webkit-keyframes slideInRight-enter {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight-enter {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInRight-leave {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slideInRight-leave {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@-webkit-keyframes slideInLeft-enter {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInLeft-enter {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInLeft-leave {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slideInLeft-leave {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.el-tag {
  height: 24px;
  color: #fff;
  box-sizing: border-box;
  background-color: #8391a5;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 22px;
}

.el-tag .el-icon-close {
  text-align: center;
  cursor: pointer;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  border-radius: 50%;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  top: -1px;
  right: -2px;
  -webkit-transform: scale(.75);
  transform: scale(.75);
}

.el-tag .el-icon-close:hover {
  color: #8391a5;
  background-color: #fff;
}

.el-tag--gray {
  color: #48576a;
  background-color: #e4e8f1;
  border-color: #e4e8f1;
}

.el-tag--gray .el-tag__close:hover {
  color: #fff;
  background-color: #48576a;
}

.el-tag--gray.is-hit {
  border-color: #48576a;
}

.el-tag--primary {
  color: #20a0ff;
  background-color: rgba(32, 160, 255, .1);
  border-color: rgba(32, 160, 255, .2);
}

.el-tag--primary .el-tag__close:hover {
  color: #fff;
  background-color: #20a0ff;
}

.el-tag--primary.is-hit {
  border-color: #20a0ff;
}

.el-tag--success {
  color: #13ce66;
  background-color: rgba(18, 206, 102, .1);
  border-color: rgba(18, 206, 102, .2);
}

.el-tag--success .el-tag__close:hover {
  color: #fff;
  background-color: #13ce66;
}

.el-tag--success.is-hit {
  border-color: #13ce66;
}

.el-tag--warning {
  color: #f7ba2a;
  background-color: rgba(247, 186, 41, .1);
  border-color: rgba(247, 186, 41, .2);
}

.el-tag--warning .el-tag__close:hover {
  color: #fff;
  background-color: #f7ba2a;
}

.el-tag--warning.is-hit {
  border-color: #f7ba2a;
}

.el-tag--danger {
  color: #ff4949;
  background-color: rgba(255, 73, 73, .1);
  border-color: rgba(255, 73, 73, .2);
}

.el-tag--danger .el-tag__close:hover {
  color: #fff;
  background-color: #ff4949;
}

.el-tag--danger.is-hit {
  border-color: #ff4949;
}

.el-tree {
  cursor: default;
  background: #fff;
  border: 1px solid #d1dbe5;
}

.el-tree__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.el-tree__empty-text {
  color: #5e7382;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.el-tree-node > .el-tree-node__children {
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
}

.el-tree-node.is-expanded > .el-tree-node__children {
  display: block;
}

.el-tree-node__expand-icon, .el-tree-node__label, .el-tree-node__loading-icon {
  vertical-align: middle;
  display: inline-block;
}

.el-tree-node__content {
  height: 36px;
  cursor: pointer;
  line-height: 36px;
}

.el-tree-node__content > .el-checkbox, .el-tree-node__content > .el-tree-node__expand-icon {
  margin-right: 8px;
}

.el-tree-node__content > .el-checkbox {
  vertical-align: middle;
}

.el-tree-node__content:hover {
  background: #e4e8f1;
}

.el-tree-node__expand-icon {
  cursor: pointer;
  width: 0;
  height: 0;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  border: 6px solid rgba(0, 0, 0, 0);
  border-left: 7px solid #97a8be;
  border-right-width: 0;
  margin-left: 10px;
  transition: -webkit-transform .3s ease-in-out, transform .3s ease-in-out;
}

.el-tree-node__expand-icon:hover {
  border-left-color: #999;
}

.el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.el-tree-node__expand-icon.is-leaf {
  cursor: default;
  border-color: rgba(0, 0, 0, 0);
}

.el-tree-node__label {
  font-size: 14px;
}

.el-tree-node__loading-icon {
  color: #97a8be;
  margin-right: 4px;
  font-size: 14px;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #edf7ff;
}

.el-alert {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  opacity: 1;
  background-color: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 8px 16px;
  transition: opacity .2s;
  display: table;
  position: relative;
  overflow: hidden;
}

.el-alert .el-alert__description {
  color: #fff;
  margin: 5px 0 0;
  font-size: 12px;
}

.el-alert--success {
  background-color: #13ce66;
}

.el-alert--info {
  background-color: #50bfff;
}

.el-alert--warning {
  background-color: #f7ba2a;
}

.el-alert--error {
  background-color: #ff4949;
}

.el-alert__content {
  padding: 0 8px;
  display: table-cell;
}

.el-alert__icon {
  width: 16px;
  color: #fff;
  vertical-align: middle;
  font-size: 16px;
  display: table-cell;
}

.el-alert__icon.is-big {
  width: 28px;
  font-size: 28px;
}

.el-alert__title {
  font-size: 13px;
  line-height: 18px;
}

.el-alert__title.is-bold {
  font-weight: 700;
}

.el-alert__closebtn {
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.el-alert-fade-enter, .el-alert-fade-leave-active, .el-loading-fade-enter, .el-loading-fade-leave-active, .el-notification-fade-leave-active {
  opacity: 0;
}

.el-alert__closebtn.is-customed {
  font-size: 13px;
  font-style: normal;
  top: 9px;
}

.el-notification {
  width: 330px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;
  transition: opacity .3s, -webkit-transform .3s, transform .3s, right .3s, top .4s;
  position: fixed;
  right: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-notification .el-icon-circle-check {
  color: #13ce66;
}

.el-notification .el-icon-circle-cross {
  color: #ff4949;
}

.el-notification .el-icon-information {
  color: #50bfff;
}

.el-notification .el-icon-warning {
  color: #f7ba2a;
}

.el-notification__group {
  margin-left: 0;
}

.el-notification__group.is-with-icon {
  margin-left: 55px;
}

.el-notification__title {
  color: #1f2d3d;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.el-notification__content {
  color: #8391a5;
  text-align: justify;
  margin: 10px 0 0;
  font-size: 14px;
  line-height: 21px;
}

.el-notification__icon {
  width: 40px;
  height: 40px;
  float: left;
  font-size: 40px;
  position: relative;
  top: 3px;
}

.el-notification__closeBtn {
  cursor: pointer;
  color: #bfcbd9;
  font-size: 14px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.el-notification__closeBtn:hover {
  color: #97a8be;
}

.el-notification-fade-enter {
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.el-input-number {
  width: 180px;
  line-height: normal;
  display: inline-block;
  position: relative;
}

.el-input-number .el-input {
  display: block;
}

.el-input-number .el-input__inner {
  -webkit-appearance: none;
  appearance: none;
  padding-right: 82px;
}

.el-input-number.is-without-controls .el-input__inner {
  padding-right: 10px;
}

.el-input-number.is-disabled .el-input-number__decrease, .el-input-number.is-disabled .el-input-number__increase {
  color: #d1dbe5;
  border-color: #d1dbe5;
}

.el-input-number.is-disabled .el-input-number__decrease:hover, .el-input-number.is-disabled .el-input-number__increase:hover {
  color: #d1dbe5;
  cursor: not-allowed;
}

.el-input-number__decrease, .el-input-number__increase {
  height: auto;
  width: 36px;
  text-align: center;
  color: #97a8be;
  cursor: pointer;
  z-index: 1;
  border-left: 1px solid #bfcbd9;
  line-height: 34px;
  position: absolute;
  top: 1px;
}

.el-input-number__decrease:hover, .el-input-number__increase:hover {
  color: #20a0ff;
}

.el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
  border-color: #20a0ff;
}

.el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled {
  color: #d1dbe5;
  cursor: not-allowed;
}

.el-input-number__increase {
  right: 0;
}

.el-input-number__decrease {
  right: 37px;
}

.el-input-number--large {
  width: 200px;
}

.el-input-number--large .el-input-number__decrease, .el-input-number--large .el-input-number__increase {
  width: 42px;
  font-size: 16px;
  line-height: 40px;
}

.el-input-number--large .el-input-number__decrease {
  right: 43px;
}

.el-input-number--large .el-input__inner {
  padding-right: 94px;
}

.el-input-number--small {
  width: 130px;
}

.el-input-number--small .el-input-number__decrease, .el-input-number--small .el-input-number__increase {
  width: 30px;
  font-size: 13px;
  line-height: 28px;
}

.el-input-number--small .el-input-number__decrease {
  right: 31px;
}

.el-input-number--small .el-input__inner {
  padding-right: 70px;
}

.el-tooltip__popper {
  z-index: 2000;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 1.2;
  position: absolute;
}

.el-tooltip__popper .popper__arrow, .el-tooltip__popper .popper__arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.el-tooltip__popper .popper__arrow {
  border-width: 6px;
}

.el-tooltip__popper .popper__arrow:after {
  content: " ";
  border-width: 5px;
}

.el-progress-bar__inner:after, .el-row:after, .el-row:before, .el-slider:after, .el-slider:before, .el-slider__button-wrapper:after, .el-upload-cover:after {
  content: "";
}

.el-tooltip__popper[x-placement^="top"] {
  margin-bottom: 12px;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #1f2d3d;
  border-bottom-width: 0;
  bottom: -6px;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: #1f2d3d;
  border-bottom-width: 0;
  margin-left: -5px;
  bottom: 1px;
}

.el-tooltip__popper[x-placement^="bottom"] {
  margin-top: 12px;
}

.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-top-width: 0;
  border-bottom-color: #1f2d3d;
  top: -6px;
}

.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after {
  border-top-width: 0;
  border-bottom-color: #1f2d3d;
  margin-left: -5px;
  top: 1px;
}

.el-tooltip__popper[x-placement^="right"] {
  margin-left: 12px;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-width: 0;
  border-right-color: #1f2d3d;
  left: -6px;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow:after {
  border-left-width: 0;
  border-right-color: #1f2d3d;
  bottom: -5px;
  left: 1px;
}

.el-tooltip__popper[x-placement^="left"] {
  margin-right: 12px;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #1f2d3d;
  border-right-width: 0;
  right: -6px;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow:after {
  border-left-color: #1f2d3d;
  border-right-width: 0;
  margin-left: -5px;
  bottom: -5px;
  right: 1px;
}

.el-tooltip__popper.is-light {
  background: #fff;
  border: 1px solid #1f2d3d;
}

.el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow {
  border-top-color: #1f2d3d;
}

.el-tooltip__popper.is-light[x-placement^="top"] .popper__arrow:after {
  border-top-color: #fff;
}

.el-tooltip__popper.is-light[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #1f2d3d;
}

.el-tooltip__popper.is-light[x-placement^="bottom"] .popper__arrow:after {
  border-bottom-color: #fff;
}

.el-tooltip__popper.is-light[x-placement^="left"] .popper__arrow {
  border-left-color: #1f2d3d;
}

.el-tooltip__popper.is-light[x-placement^="left"] .popper__arrow:after {
  border-left-color: #fff;
}

.el-tooltip__popper.is-light[x-placement^="right"] .popper__arrow {
  border-right-color: #1f2d3d;
}

.el-tooltip__popper.is-light[x-placement^="right"] .popper__arrow:after {
  border-right-color: #fff;
}

.el-tooltip__popper.is-dark {
  color: #fff;
  background: #1f2d3d;
}

.el-slider:after, .el-slider:before {
  display: table;
}

.el-slider__button-wrapper .el-tooltip, .el-slider__button-wrapper:after {
  vertical-align: middle;
  display: inline-block;
}

.el-slider.is-vertical {
  position: relative;
}

.el-slider.is-vertical .el-slider__runway {
  width: 4px;
  height: 100%;
  margin: 0 16px;
}

.el-slider.is-vertical .el-slider__bar {
  width: 4px;
  height: auto;
  border-radius: 0 0 3px 3px;
}

.el-slider.is-vertical .el-slider__button-wrapper {
  top: auto;
  left: -16px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.el-slider.is-vertical .el-slider__stop {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.el-slider.is-vertical.el-slider--with-input {
  padding-bottom: 64px;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input {
  float: none;
  width: 36px;
  margin-top: 15px;
  position: absolute;
  bottom: 22px;
  overflow: visible;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input__inner {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__decrease, .el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase {
  box-sizing: border-box;
  border: 1px solid #bfcbd9;
  margin-top: -1px;
  line-height: 20px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  top: 30px;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__decrease {
  width: 18px;
  border-bottom-left-radius: 4px;
  right: 18px;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase {
  width: 19px;
  border-bottom-right-radius: 4px;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input .el-input-number__increase ~ .el-input .el-input__inner {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input:hover .el-input-number__decrease, .el-slider.is-vertical.el-slider--with-input .el-slider__input:hover .el-input-number__increase {
  border-color: #8391a5;
}

.el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__decrease, .el-slider.is-vertical.el-slider--with-input .el-slider__input:active .el-input-number__increase {
  border-color: #20a0ff;
}

.el-slider__runway {
  width: 100%;
  height: 4px;
  cursor: pointer;
  vertical-align: middle;
  background-color: #e4e8f1;
  border-radius: 3px;
  margin: 16px 0;
  position: relative;
}

.el-slider__runway.show-input {
  width: auto;
  margin-right: 160px;
}

.el-slider__runway.disabled {
  cursor: default;
}

.el-slider__runway.disabled .el-slider__bar, .el-slider__runway.disabled .el-slider__button {
  background-color: #bfcbd9;
}

.el-slider__runway.disabled .el-slider__button-wrapper.dragging, .el-slider__runway.disabled .el-slider__button-wrapper.hover, .el-slider__runway.disabled .el-slider__button-wrapper:hover {
  cursor: not-allowed;
}

.el-slider__runway.disabled .el-slider__button.dragging, .el-slider__runway.disabled .el-slider__button.hover, .el-slider__runway.disabled .el-slider__button:hover {
  cursor: not-allowed;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.el-slider__input {
  float: right;
  margin-top: 3px;
}

.el-slider__bar {
  height: 4px;
  background-color: #20a0ff;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
}

.el-slider__button-wrapper {
  width: 36px;
  height: 36px;
  z-index: 1001;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: -16px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.el-slider__button-wrapper:after {
  height: 100%;
}

.el-slider__button-wrapper.hover, .el-slider__button-wrapper:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.el-slider__button-wrapper.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.el-slider__button {
  width: 12px;
  height: 12px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #20a0ff;
  border-radius: 50%;
  transition: all .2s;
}

.el-slider__button.dragging, .el-slider__button.hover, .el-slider__button:hover {
  background-color: #1c8de0;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.el-slider__button.hover, .el-slider__button:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.el-slider__button.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.el-slider__stop {
  width: 4px;
  height: 4px;
  background-color: #bfcbd9;
  border-radius: 100%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.el-loading-mask {
  z-index: 10000;
  background-color: rgba(255, 255, 255, .9);
  margin: 0;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-loading-mask.is-fullscreen {
  position: fixed;
}

.el-loading-mask.is-fullscreen .el-loading-spinner {
  margin-top: -25px;
}

.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  width: 50px;
  height: 50px;
}

.el-loading-spinner {
  width: 100%;
  text-align: center;
  margin-top: -21px;
  position: absolute;
  top: 50%;
}

.el-col-pull-0, .el-col-pull-1, .el-col-pull-10, .el-col-pull-11, .el-col-pull-13, .el-col-pull-14, .el-col-pull-15, .el-col-pull-16, .el-col-pull-17, .el-col-pull-18, .el-col-pull-19, .el-col-pull-2, .el-col-pull-20, .el-col-pull-21, .el-col-pull-22, .el-col-pull-23, .el-col-pull-24, .el-col-pull-3, .el-col-pull-4, .el-col-pull-5, .el-col-pull-6, .el-col-pull-7, .el-col-pull-8, .el-col-pull-9, .el-col-push-0, .el-col-push-1, .el-col-push-10, .el-col-push-11, .el-col-push-12, .el-col-push-13, .el-col-push-14, .el-col-push-15, .el-col-push-16, .el-col-push-17, .el-col-push-18, .el-col-push-19, .el-col-push-2, .el-col-push-20, .el-col-push-21, .el-col-push-22, .el-col-push-23, .el-col-push-24, .el-col-push-3, .el-col-push-4, .el-col-push-5, .el-col-push-6, .el-col-push-7, .el-col-push-8, .el-col-push-9, .el-row {
  position: relative;
}

.el-loading-spinner .el-loading-text {
  color: #20a0ff;
  margin: 3px 0;
  font-size: 14px;
}

.el-loading-spinner .circular {
  width: 42px;
  height: 42px;
  -webkit-animation: loading-rotate 2s linear infinite;
  animation: loading-rotate 2s linear infinite;
}

.el-loading-spinner .path {
  stroke-dasharray: 90 150;
  stroke-dashoffset: 0;
  stroke-width: 2px;
  stroke: #20a0ff;
  stroke-linecap: round;
  -webkit-animation: loading-dash 1.5s ease-in-out infinite;
  animation: loading-dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -40px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -120px;
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -40px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -120px;
  }
}

.el-row {
  box-sizing: border-box;
}

.el-row:after, .el-row:before {
  display: table;
}

.el-row--flex {
  display: flex;
}

.el-row--flex:after, .el-row--flex:before {
  display: none;
}

.el-row--flex.is-align-bottom {
  align-items: flex-end;
}

.el-row--flex.is-align-middle {
  align-items: center;
}

.el-row--flex.is-justify-space-around {
  justify-content: space-around;
}

.el-row--flex.is-justify-space-between {
  justify-content: space-between;
}

.el-row--flex.is-justify-end {
  justify-content: flex-end;
}

.el-row--flex.is-justify-center {
  justify-content: center;
}

.el-col-1, .el-col-10, .el-col-11, .el-col-12, .el-col-13, .el-col-14, .el-col-15, .el-col-16, .el-col-17, .el-col-18, .el-col-19, .el-col-2, .el-col-20, .el-col-21, .el-col-22, .el-col-23, .el-col-24, .el-col-3, .el-col-4, .el-col-5, .el-col-6, .el-col-7, .el-col-8, .el-col-9 {
  float: left;
  box-sizing: border-box;
}

.el-col-0 {
  width: 0;
}

.el-col-offset-0 {
  margin-left: 0;
}

.el-col-pull-0 {
  right: 0;
}

.el-col-push-0 {
  left: 0;
}

.el-col-1 {
  width: 4.16667%;
}

.el-col-offset-1 {
  margin-left: 4.16667%;
}

.el-col-pull-1 {
  right: 4.16667%;
}

.el-col-push-1 {
  left: 4.16667%;
}

.el-col-2 {
  width: 8.33333%;
}

.el-col-offset-2 {
  margin-left: 8.33333%;
}

.el-col-pull-2 {
  right: 8.33333%;
}

.el-col-push-2 {
  left: 8.33333%;
}

.el-col-3 {
  width: 12.5%;
}

.el-col-offset-3 {
  margin-left: 12.5%;
}

.el-col-pull-3 {
  right: 12.5%;
}

.el-col-push-3 {
  left: 12.5%;
}

.el-col-4 {
  width: 16.6667%;
}

.el-col-offset-4 {
  margin-left: 16.6667%;
}

.el-col-pull-4 {
  right: 16.6667%;
}

.el-col-push-4 {
  left: 16.6667%;
}

.el-col-5 {
  width: 20.8333%;
}

.el-col-offset-5 {
  margin-left: 20.8333%;
}

.el-col-pull-5 {
  right: 20.8333%;
}

.el-col-push-5 {
  left: 20.8333%;
}

.el-col-6 {
  width: 25%;
}

.el-col-offset-6 {
  margin-left: 25%;
}

.el-col-pull-6 {
  right: 25%;
}

.el-col-push-6 {
  left: 25%;
}

.el-col-7 {
  width: 29.1667%;
}

.el-col-offset-7 {
  margin-left: 29.1667%;
}

.el-col-pull-7 {
  right: 29.1667%;
}

.el-col-push-7 {
  left: 29.1667%;
}

.el-col-8 {
  width: 33.3333%;
}

.el-col-offset-8 {
  margin-left: 33.3333%;
}

.el-col-pull-8 {
  right: 33.3333%;
}

.el-col-push-8 {
  left: 33.3333%;
}

.el-col-9 {
  width: 37.5%;
}

.el-col-offset-9 {
  margin-left: 37.5%;
}

.el-col-pull-9 {
  right: 37.5%;
}

.el-col-push-9 {
  left: 37.5%;
}

.el-col-10 {
  width: 41.6667%;
}

.el-col-offset-10 {
  margin-left: 41.6667%;
}

.el-col-pull-10 {
  right: 41.6667%;
}

.el-col-push-10 {
  left: 41.6667%;
}

.el-col-11 {
  width: 45.8333%;
}

.el-col-offset-11 {
  margin-left: 45.8333%;
}

.el-col-pull-11 {
  right: 45.8333%;
}

.el-col-push-11 {
  left: 45.8333%;
}

.el-col-12 {
  width: 50%;
}

.el-col-offset-12 {
  margin-left: 50%;
}

.el-col-pull-12 {
  position: relative;
  right: 50%;
}

.el-col-push-12 {
  left: 50%;
}

.el-col-13 {
  width: 54.1667%;
}

.el-col-offset-13 {
  margin-left: 54.1667%;
}

.el-col-pull-13 {
  right: 54.1667%;
}

.el-col-push-13 {
  left: 54.1667%;
}

.el-col-14 {
  width: 58.3333%;
}

.el-col-offset-14 {
  margin-left: 58.3333%;
}

.el-col-pull-14 {
  right: 58.3333%;
}

.el-col-push-14 {
  left: 58.3333%;
}

.el-col-15 {
  width: 62.5%;
}

.el-col-offset-15 {
  margin-left: 62.5%;
}

.el-col-pull-15 {
  right: 62.5%;
}

.el-col-push-15 {
  left: 62.5%;
}

.el-col-16 {
  width: 66.6667%;
}

.el-col-offset-16 {
  margin-left: 66.6667%;
}

.el-col-pull-16 {
  right: 66.6667%;
}

.el-col-push-16 {
  left: 66.6667%;
}

.el-col-17 {
  width: 70.8333%;
}

.el-col-offset-17 {
  margin-left: 70.8333%;
}

.el-col-pull-17 {
  right: 70.8333%;
}

.el-col-push-17 {
  left: 70.8333%;
}

.el-col-18 {
  width: 75%;
}

.el-col-offset-18 {
  margin-left: 75%;
}

.el-col-pull-18 {
  right: 75%;
}

.el-col-push-18 {
  left: 75%;
}

.el-col-19 {
  width: 79.1667%;
}

.el-col-offset-19 {
  margin-left: 79.1667%;
}

.el-col-pull-19 {
  right: 79.1667%;
}

.el-col-push-19 {
  left: 79.1667%;
}

.el-col-20 {
  width: 83.3333%;
}

.el-col-offset-20 {
  margin-left: 83.3333%;
}

.el-col-pull-20 {
  right: 83.3333%;
}

.el-col-push-20 {
  left: 83.3333%;
}

.el-col-21 {
  width: 87.5%;
}

.el-col-offset-21 {
  margin-left: 87.5%;
}

.el-col-pull-21 {
  right: 87.5%;
}

.el-col-push-21 {
  left: 87.5%;
}

.el-col-22 {
  width: 91.6667%;
}

.el-col-offset-22 {
  margin-left: 91.6667%;
}

.el-col-pull-22 {
  right: 91.6667%;
}

.el-col-push-22 {
  left: 91.6667%;
}

.el-col-23 {
  width: 95.8333%;
}

.el-col-offset-23 {
  margin-left: 95.8333%;
}

.el-col-pull-23 {
  right: 95.8333%;
}

.el-col-push-23 {
  left: 95.8333%;
}

.el-col-24 {
  width: 100%;
}

.el-col-offset-24 {
  margin-left: 100%;
}

.el-col-pull-24 {
  right: 100%;
}

.el-col-push-24 {
  left: 100%;
}

@media (max-width: 768px) {
  .el-col-xs-0 {
    width: 0;
  }

  .el-col-xs-offset-0 {
    margin-left: 0;
  }

  .el-col-xs-pull-0 {
    position: relative;
    right: 0;
  }

  .el-col-xs-push-0 {
    position: relative;
    left: 0;
  }

  .el-col-xs-1 {
    width: 4.16667%;
  }

  .el-col-xs-offset-1 {
    margin-left: 4.16667%;
  }

  .el-col-xs-pull-1 {
    position: relative;
    right: 4.16667%;
  }

  .el-col-xs-push-1 {
    position: relative;
    left: 4.16667%;
  }

  .el-col-xs-2 {
    width: 8.33333%;
  }

  .el-col-xs-offset-2 {
    margin-left: 8.33333%;
  }

  .el-col-xs-pull-2 {
    position: relative;
    right: 8.33333%;
  }

  .el-col-xs-push-2 {
    position: relative;
    left: 8.33333%;
  }

  .el-col-xs-3 {
    width: 12.5%;
  }

  .el-col-xs-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-xs-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-xs-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-xs-4 {
    width: 16.6667%;
  }

  .el-col-xs-offset-4 {
    margin-left: 16.6667%;
  }

  .el-col-xs-pull-4 {
    position: relative;
    right: 16.6667%;
  }

  .el-col-xs-push-4 {
    position: relative;
    left: 16.6667%;
  }

  .el-col-xs-5 {
    width: 20.8333%;
  }

  .el-col-xs-offset-5 {
    margin-left: 20.8333%;
  }

  .el-col-xs-pull-5 {
    position: relative;
    right: 20.8333%;
  }

  .el-col-xs-push-5 {
    position: relative;
    left: 20.8333%;
  }

  .el-col-xs-6 {
    width: 25%;
  }

  .el-col-xs-offset-6 {
    margin-left: 25%;
  }

  .el-col-xs-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-xs-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-xs-7 {
    width: 29.1667%;
  }

  .el-col-xs-offset-7 {
    margin-left: 29.1667%;
  }

  .el-col-xs-pull-7 {
    position: relative;
    right: 29.1667%;
  }

  .el-col-xs-push-7 {
    position: relative;
    left: 29.1667%;
  }

  .el-col-xs-8 {
    width: 33.3333%;
  }

  .el-col-xs-offset-8 {
    margin-left: 33.3333%;
  }

  .el-col-xs-pull-8 {
    position: relative;
    right: 33.3333%;
  }

  .el-col-xs-push-8 {
    position: relative;
    left: 33.3333%;
  }

  .el-col-xs-9 {
    width: 37.5%;
  }

  .el-col-xs-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-xs-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-xs-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-xs-10 {
    width: 41.6667%;
  }

  .el-col-xs-offset-10 {
    margin-left: 41.6667%;
  }

  .el-col-xs-pull-10 {
    position: relative;
    right: 41.6667%;
  }

  .el-col-xs-push-10 {
    position: relative;
    left: 41.6667%;
  }

  .el-col-xs-11 {
    width: 45.8333%;
  }

  .el-col-xs-offset-11 {
    margin-left: 45.8333%;
  }

  .el-col-xs-pull-11 {
    position: relative;
    right: 45.8333%;
  }

  .el-col-xs-push-11 {
    position: relative;
    left: 45.8333%;
  }

  .el-col-xs-12 {
    width: 50%;
  }

  .el-col-xs-offset-12 {
    margin-left: 50%;
  }

  .el-col-xs-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-xs-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-xs-13 {
    width: 54.1667%;
  }

  .el-col-xs-offset-13 {
    margin-left: 54.1667%;
  }

  .el-col-xs-pull-13 {
    position: relative;
    right: 54.1667%;
  }

  .el-col-xs-push-13 {
    position: relative;
    left: 54.1667%;
  }

  .el-col-xs-14 {
    width: 58.3333%;
  }

  .el-col-xs-offset-14 {
    margin-left: 58.3333%;
  }

  .el-col-xs-pull-14 {
    position: relative;
    right: 58.3333%;
  }

  .el-col-xs-push-14 {
    position: relative;
    left: 58.3333%;
  }

  .el-col-xs-15 {
    width: 62.5%;
  }

  .el-col-xs-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-xs-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-xs-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-xs-16 {
    width: 66.6667%;
  }

  .el-col-xs-offset-16 {
    margin-left: 66.6667%;
  }

  .el-col-xs-pull-16 {
    position: relative;
    right: 66.6667%;
  }

  .el-col-xs-push-16 {
    position: relative;
    left: 66.6667%;
  }

  .el-col-xs-17 {
    width: 70.8333%;
  }

  .el-col-xs-offset-17 {
    margin-left: 70.8333%;
  }

  .el-col-xs-pull-17 {
    position: relative;
    right: 70.8333%;
  }

  .el-col-xs-push-17 {
    position: relative;
    left: 70.8333%;
  }

  .el-col-xs-18 {
    width: 75%;
  }

  .el-col-xs-offset-18 {
    margin-left: 75%;
  }

  .el-col-xs-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-xs-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-xs-19 {
    width: 79.1667%;
  }

  .el-col-xs-offset-19 {
    margin-left: 79.1667%;
  }

  .el-col-xs-pull-19 {
    position: relative;
    right: 79.1667%;
  }

  .el-col-xs-push-19 {
    position: relative;
    left: 79.1667%;
  }

  .el-col-xs-20 {
    width: 83.3333%;
  }

  .el-col-xs-offset-20 {
    margin-left: 83.3333%;
  }

  .el-col-xs-pull-20 {
    position: relative;
    right: 83.3333%;
  }

  .el-col-xs-push-20 {
    position: relative;
    left: 83.3333%;
  }

  .el-col-xs-21 {
    width: 87.5%;
  }

  .el-col-xs-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-xs-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-xs-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-xs-22 {
    width: 91.6667%;
  }

  .el-col-xs-offset-22 {
    margin-left: 91.6667%;
  }

  .el-col-xs-pull-22 {
    position: relative;
    right: 91.6667%;
  }

  .el-col-xs-push-22 {
    position: relative;
    left: 91.6667%;
  }

  .el-col-xs-23 {
    width: 95.8333%;
  }

  .el-col-xs-offset-23 {
    margin-left: 95.8333%;
  }

  .el-col-xs-pull-23 {
    position: relative;
    right: 95.8333%;
  }

  .el-col-xs-push-23 {
    position: relative;
    left: 95.8333%;
  }

  .el-col-xs-24 {
    width: 100%;
  }

  .el-col-xs-offset-24 {
    margin-left: 100%;
  }

  .el-col-xs-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-xs-push-24 {
    position: relative;
    left: 100%;
  }
}

@media (min-width: 768px) {
  .el-col-sm-0 {
    width: 0;
  }

  .el-col-sm-offset-0 {
    margin-left: 0;
  }

  .el-col-sm-pull-0 {
    position: relative;
    right: 0;
  }

  .el-col-sm-push-0 {
    position: relative;
    left: 0;
  }

  .el-col-sm-1 {
    width: 4.16667%;
  }

  .el-col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .el-col-sm-pull-1 {
    position: relative;
    right: 4.16667%;
  }

  .el-col-sm-push-1 {
    position: relative;
    left: 4.16667%;
  }

  .el-col-sm-2 {
    width: 8.33333%;
  }

  .el-col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .el-col-sm-pull-2 {
    position: relative;
    right: 8.33333%;
  }

  .el-col-sm-push-2 {
    position: relative;
    left: 8.33333%;
  }

  .el-col-sm-3 {
    width: 12.5%;
  }

  .el-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-sm-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-sm-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-sm-4 {
    width: 16.6667%;
  }

  .el-col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .el-col-sm-pull-4 {
    position: relative;
    right: 16.6667%;
  }

  .el-col-sm-push-4 {
    position: relative;
    left: 16.6667%;
  }

  .el-col-sm-5 {
    width: 20.8333%;
  }

  .el-col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .el-col-sm-pull-5 {
    position: relative;
    right: 20.8333%;
  }

  .el-col-sm-push-5 {
    position: relative;
    left: 20.8333%;
  }

  .el-col-sm-6 {
    width: 25%;
  }

  .el-col-sm-offset-6 {
    margin-left: 25%;
  }

  .el-col-sm-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-sm-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-sm-7 {
    width: 29.1667%;
  }

  .el-col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .el-col-sm-pull-7 {
    position: relative;
    right: 29.1667%;
  }

  .el-col-sm-push-7 {
    position: relative;
    left: 29.1667%;
  }

  .el-col-sm-8 {
    width: 33.3333%;
  }

  .el-col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .el-col-sm-pull-8 {
    position: relative;
    right: 33.3333%;
  }

  .el-col-sm-push-8 {
    position: relative;
    left: 33.3333%;
  }

  .el-col-sm-9 {
    width: 37.5%;
  }

  .el-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-sm-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-sm-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-sm-10 {
    width: 41.6667%;
  }

  .el-col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .el-col-sm-pull-10 {
    position: relative;
    right: 41.6667%;
  }

  .el-col-sm-push-10 {
    position: relative;
    left: 41.6667%;
  }

  .el-col-sm-11 {
    width: 45.8333%;
  }

  .el-col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .el-col-sm-pull-11 {
    position: relative;
    right: 45.8333%;
  }

  .el-col-sm-push-11 {
    position: relative;
    left: 45.8333%;
  }

  .el-col-sm-12 {
    width: 50%;
  }

  .el-col-sm-offset-12 {
    margin-left: 50%;
  }

  .el-col-sm-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-sm-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-sm-13 {
    width: 54.1667%;
  }

  .el-col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .el-col-sm-pull-13 {
    position: relative;
    right: 54.1667%;
  }

  .el-col-sm-push-13 {
    position: relative;
    left: 54.1667%;
  }

  .el-col-sm-14 {
    width: 58.3333%;
  }

  .el-col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .el-col-sm-pull-14 {
    position: relative;
    right: 58.3333%;
  }

  .el-col-sm-push-14 {
    position: relative;
    left: 58.3333%;
  }

  .el-col-sm-15 {
    width: 62.5%;
  }

  .el-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-sm-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-sm-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-sm-16 {
    width: 66.6667%;
  }

  .el-col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .el-col-sm-pull-16 {
    position: relative;
    right: 66.6667%;
  }

  .el-col-sm-push-16 {
    position: relative;
    left: 66.6667%;
  }

  .el-col-sm-17 {
    width: 70.8333%;
  }

  .el-col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .el-col-sm-pull-17 {
    position: relative;
    right: 70.8333%;
  }

  .el-col-sm-push-17 {
    position: relative;
    left: 70.8333%;
  }

  .el-col-sm-18 {
    width: 75%;
  }

  .el-col-sm-offset-18 {
    margin-left: 75%;
  }

  .el-col-sm-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-sm-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-sm-19 {
    width: 79.1667%;
  }

  .el-col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .el-col-sm-pull-19 {
    position: relative;
    right: 79.1667%;
  }

  .el-col-sm-push-19 {
    position: relative;
    left: 79.1667%;
  }

  .el-col-sm-20 {
    width: 83.3333%;
  }

  .el-col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .el-col-sm-pull-20 {
    position: relative;
    right: 83.3333%;
  }

  .el-col-sm-push-20 {
    position: relative;
    left: 83.3333%;
  }

  .el-col-sm-21 {
    width: 87.5%;
  }

  .el-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-sm-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-sm-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-sm-22 {
    width: 91.6667%;
  }

  .el-col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .el-col-sm-pull-22 {
    position: relative;
    right: 91.6667%;
  }

  .el-col-sm-push-22 {
    position: relative;
    left: 91.6667%;
  }

  .el-col-sm-23 {
    width: 95.8333%;
  }

  .el-col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .el-col-sm-pull-23 {
    position: relative;
    right: 95.8333%;
  }

  .el-col-sm-push-23 {
    position: relative;
    left: 95.8333%;
  }

  .el-col-sm-24 {
    width: 100%;
  }

  .el-col-sm-offset-24 {
    margin-left: 100%;
  }

  .el-col-sm-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-sm-push-24 {
    position: relative;
    left: 100%;
  }
}

@media (min-width: 992px) {
  .el-col-md-0 {
    width: 0;
  }

  .el-col-md-offset-0 {
    margin-left: 0;
  }

  .el-col-md-pull-0 {
    position: relative;
    right: 0;
  }

  .el-col-md-push-0 {
    position: relative;
    left: 0;
  }

  .el-col-md-1 {
    width: 4.16667%;
  }

  .el-col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .el-col-md-pull-1 {
    position: relative;
    right: 4.16667%;
  }

  .el-col-md-push-1 {
    position: relative;
    left: 4.16667%;
  }

  .el-col-md-2 {
    width: 8.33333%;
  }

  .el-col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .el-col-md-pull-2 {
    position: relative;
    right: 8.33333%;
  }

  .el-col-md-push-2 {
    position: relative;
    left: 8.33333%;
  }

  .el-col-md-3 {
    width: 12.5%;
  }

  .el-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-md-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-md-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-md-4 {
    width: 16.6667%;
  }

  .el-col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .el-col-md-pull-4 {
    position: relative;
    right: 16.6667%;
  }

  .el-col-md-push-4 {
    position: relative;
    left: 16.6667%;
  }

  .el-col-md-5 {
    width: 20.8333%;
  }

  .el-col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .el-col-md-pull-5 {
    position: relative;
    right: 20.8333%;
  }

  .el-col-md-push-5 {
    position: relative;
    left: 20.8333%;
  }

  .el-col-md-6 {
    width: 25%;
  }

  .el-col-md-offset-6 {
    margin-left: 25%;
  }

  .el-col-md-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-md-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-md-7 {
    width: 29.1667%;
  }

  .el-col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .el-col-md-pull-7 {
    position: relative;
    right: 29.1667%;
  }

  .el-col-md-push-7 {
    position: relative;
    left: 29.1667%;
  }

  .el-col-md-8 {
    width: 33.3333%;
  }

  .el-col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .el-col-md-pull-8 {
    position: relative;
    right: 33.3333%;
  }

  .el-col-md-push-8 {
    position: relative;
    left: 33.3333%;
  }

  .el-col-md-9 {
    width: 37.5%;
  }

  .el-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-md-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-md-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-md-10 {
    width: 41.6667%;
  }

  .el-col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .el-col-md-pull-10 {
    position: relative;
    right: 41.6667%;
  }

  .el-col-md-push-10 {
    position: relative;
    left: 41.6667%;
  }

  .el-col-md-11 {
    width: 45.8333%;
  }

  .el-col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .el-col-md-pull-11 {
    position: relative;
    right: 45.8333%;
  }

  .el-col-md-push-11 {
    position: relative;
    left: 45.8333%;
  }

  .el-col-md-12 {
    width: 50%;
  }

  .el-col-md-offset-12 {
    margin-left: 50%;
  }

  .el-col-md-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-md-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-md-13 {
    width: 54.1667%;
  }

  .el-col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .el-col-md-pull-13 {
    position: relative;
    right: 54.1667%;
  }

  .el-col-md-push-13 {
    position: relative;
    left: 54.1667%;
  }

  .el-col-md-14 {
    width: 58.3333%;
  }

  .el-col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .el-col-md-pull-14 {
    position: relative;
    right: 58.3333%;
  }

  .el-col-md-push-14 {
    position: relative;
    left: 58.3333%;
  }

  .el-col-md-15 {
    width: 62.5%;
  }

  .el-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-md-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-md-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-md-16 {
    width: 66.6667%;
  }

  .el-col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .el-col-md-pull-16 {
    position: relative;
    right: 66.6667%;
  }

  .el-col-md-push-16 {
    position: relative;
    left: 66.6667%;
  }

  .el-col-md-17 {
    width: 70.8333%;
  }

  .el-col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .el-col-md-pull-17 {
    position: relative;
    right: 70.8333%;
  }

  .el-col-md-push-17 {
    position: relative;
    left: 70.8333%;
  }

  .el-col-md-18 {
    width: 75%;
  }

  .el-col-md-offset-18 {
    margin-left: 75%;
  }

  .el-col-md-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-md-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-md-19 {
    width: 79.1667%;
  }

  .el-col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .el-col-md-pull-19 {
    position: relative;
    right: 79.1667%;
  }

  .el-col-md-push-19 {
    position: relative;
    left: 79.1667%;
  }

  .el-col-md-20 {
    width: 83.3333%;
  }

  .el-col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .el-col-md-pull-20 {
    position: relative;
    right: 83.3333%;
  }

  .el-col-md-push-20 {
    position: relative;
    left: 83.3333%;
  }

  .el-col-md-21 {
    width: 87.5%;
  }

  .el-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-md-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-md-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-md-22 {
    width: 91.6667%;
  }

  .el-col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .el-col-md-pull-22 {
    position: relative;
    right: 91.6667%;
  }

  .el-col-md-push-22 {
    position: relative;
    left: 91.6667%;
  }

  .el-col-md-23 {
    width: 95.8333%;
  }

  .el-col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .el-col-md-pull-23 {
    position: relative;
    right: 95.8333%;
  }

  .el-col-md-push-23 {
    position: relative;
    left: 95.8333%;
  }

  .el-col-md-24 {
    width: 100%;
  }

  .el-col-md-offset-24 {
    margin-left: 100%;
  }

  .el-col-md-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-md-push-24 {
    position: relative;
    left: 100%;
  }
}

@media (min-width: 1200px) {
  .el-col-lg-0 {
    width: 0;
  }

  .el-col-lg-offset-0 {
    margin-left: 0;
  }

  .el-col-lg-pull-0 {
    position: relative;
    right: 0;
  }

  .el-col-lg-push-0 {
    position: relative;
    left: 0;
  }

  .el-col-lg-1 {
    width: 4.16667%;
  }

  .el-col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .el-col-lg-pull-1 {
    position: relative;
    right: 4.16667%;
  }

  .el-col-lg-push-1 {
    position: relative;
    left: 4.16667%;
  }

  .el-col-lg-2 {
    width: 8.33333%;
  }

  .el-col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .el-col-lg-pull-2 {
    position: relative;
    right: 8.33333%;
  }

  .el-col-lg-push-2 {
    position: relative;
    left: 8.33333%;
  }

  .el-col-lg-3 {
    width: 12.5%;
  }

  .el-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-lg-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-lg-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-lg-4 {
    width: 16.6667%;
  }

  .el-col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .el-col-lg-pull-4 {
    position: relative;
    right: 16.6667%;
  }

  .el-col-lg-push-4 {
    position: relative;
    left: 16.6667%;
  }

  .el-col-lg-5 {
    width: 20.8333%;
  }

  .el-col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .el-col-lg-pull-5 {
    position: relative;
    right: 20.8333%;
  }

  .el-col-lg-push-5 {
    position: relative;
    left: 20.8333%;
  }

  .el-col-lg-6 {
    width: 25%;
  }

  .el-col-lg-offset-6 {
    margin-left: 25%;
  }

  .el-col-lg-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-lg-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-lg-7 {
    width: 29.1667%;
  }

  .el-col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .el-col-lg-pull-7 {
    position: relative;
    right: 29.1667%;
  }

  .el-col-lg-push-7 {
    position: relative;
    left: 29.1667%;
  }

  .el-col-lg-8 {
    width: 33.3333%;
  }

  .el-col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .el-col-lg-pull-8 {
    position: relative;
    right: 33.3333%;
  }

  .el-col-lg-push-8 {
    position: relative;
    left: 33.3333%;
  }

  .el-col-lg-9 {
    width: 37.5%;
  }

  .el-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-lg-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-lg-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-lg-10 {
    width: 41.6667%;
  }

  .el-col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .el-col-lg-pull-10 {
    position: relative;
    right: 41.6667%;
  }

  .el-col-lg-push-10 {
    position: relative;
    left: 41.6667%;
  }

  .el-col-lg-11 {
    width: 45.8333%;
  }

  .el-col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .el-col-lg-pull-11 {
    position: relative;
    right: 45.8333%;
  }

  .el-col-lg-push-11 {
    position: relative;
    left: 45.8333%;
  }

  .el-col-lg-12 {
    width: 50%;
  }

  .el-col-lg-offset-12 {
    margin-left: 50%;
  }

  .el-col-lg-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-lg-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-lg-13 {
    width: 54.1667%;
  }

  .el-col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .el-col-lg-pull-13 {
    position: relative;
    right: 54.1667%;
  }

  .el-col-lg-push-13 {
    position: relative;
    left: 54.1667%;
  }

  .el-col-lg-14 {
    width: 58.3333%;
  }

  .el-col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .el-col-lg-pull-14 {
    position: relative;
    right: 58.3333%;
  }

  .el-col-lg-push-14 {
    position: relative;
    left: 58.3333%;
  }

  .el-col-lg-15 {
    width: 62.5%;
  }

  .el-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-lg-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-lg-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-lg-16 {
    width: 66.6667%;
  }

  .el-col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .el-col-lg-pull-16 {
    position: relative;
    right: 66.6667%;
  }

  .el-col-lg-push-16 {
    position: relative;
    left: 66.6667%;
  }

  .el-col-lg-17 {
    width: 70.8333%;
  }

  .el-col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .el-col-lg-pull-17 {
    position: relative;
    right: 70.8333%;
  }

  .el-col-lg-push-17 {
    position: relative;
    left: 70.8333%;
  }

  .el-col-lg-18 {
    width: 75%;
  }

  .el-col-lg-offset-18 {
    margin-left: 75%;
  }

  .el-col-lg-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-lg-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-lg-19 {
    width: 79.1667%;
  }

  .el-col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .el-col-lg-pull-19 {
    position: relative;
    right: 79.1667%;
  }

  .el-col-lg-push-19 {
    position: relative;
    left: 79.1667%;
  }

  .el-col-lg-20 {
    width: 83.3333%;
  }

  .el-col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .el-col-lg-pull-20 {
    position: relative;
    right: 83.3333%;
  }

  .el-col-lg-push-20 {
    position: relative;
    left: 83.3333%;
  }

  .el-col-lg-21 {
    width: 87.5%;
  }

  .el-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-lg-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-lg-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-lg-22 {
    width: 91.6667%;
  }

  .el-col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .el-col-lg-pull-22 {
    position: relative;
    right: 91.6667%;
  }

  .el-col-lg-push-22 {
    position: relative;
    left: 91.6667%;
  }

  .el-col-lg-23 {
    width: 95.8333%;
  }

  .el-col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .el-col-lg-pull-23 {
    position: relative;
    right: 95.8333%;
  }

  .el-col-lg-push-23 {
    position: relative;
    left: 95.8333%;
  }

  .el-col-lg-24 {
    width: 100%;
  }

  .el-col-lg-offset-24 {
    margin-left: 100%;
  }

  .el-col-lg-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-lg-push-24 {
    position: relative;
    left: 100%;
  }
}

.el-progress-bar__inner:after {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.el-upload {
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.el-upload iframe {
  z-index: -1;
  opacity: 0;
  -webkit-filter: alpha(opacity= 0);
  filter: alpha(opacity= 0);
  position: absolute;
  top: 0;
  left: 0;
}

.el-upload__input {
  display: none;
}

.el-upload__tip {
  color: #8391a5;
  margin-top: 7px;
  font-size: 12px;
}

.el-upload--picture-card {
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  vertical-align: top;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  line-height: 146px;
}

.el-upload--picture-card i {
  color: #8c939d;
  font-size: 28px;
}

.el-upload--picture-card:hover {
  color: #20a0ff;
  border-color: #20a0ff;
}

.el-upload-dragger {
  box-sizing: border-box;
  width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.el-upload-dragger .el-upload__text {
  color: #97a8be;
  text-align: center;
  font-size: 14px;
}

.el-upload-dragger .el-upload__text em {
  color: #20a0ff;
  font-style: normal;
}

.el-upload-dragger .el-icon-upload {
  color: #97a8be;
  margin: 40px 0 16px;
  font-size: 67px;
  line-height: 50px;
}

.el-upload-dragger + .el-upload__tip {
  text-align: center;
}

.el-upload-dragger ~ .el-upload__files {
  border-top: 1px solid rgba(191, 203, 217, .2);
  margin-top: 7px;
  padding-top: 5px;
}

.el-upload-dragger:hover {
  border-color: #20a0ff;
}

.el-upload-dragger.is-dragover {
  background-color: rgba(32, 159, 255, .06);
  border: 2px dashed #20a0ff;
}

.el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.el-upload-list.is-disabled .el-upload-list__item:hover .el-upload-list__item-status-label {
  display: block;
}

.el-upload-list__item {
  color: #48576a;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.8;
  transition: all .5s cubic-bezier(.55, 0, .1, 1);
  position: relative;
}

.el-upload-list__item .el-progress-bar {
  margin-right: 0;
  padding-right: 0;
}

.el-upload-list__item .el-progress {
  width: 100%;
  position: absolute;
  top: 20px;
}

.el-upload-list__item .el-progress__text {
  position: absolute;
  top: -13px;
  right: 0;
}

.el-upload-list__item:first-child {
  margin-top: 10px;
}

.el-upload-list__item .el-icon-upload-success {
  color: #13ce66;
}

.el-upload-list__item .el-icon-close {
  cursor: pointer;
  opacity: .75;
  color: #48576a;
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-transform: scale(.7);
  transform: scale(.7);
}

.el-upload-list__item .el-icon-close:hover {
  opacity: 1;
}

.el-upload-list__item:hover {
  background-color: #eef1f6;
}

.el-upload-list__item:hover .el-icon-close {
  display: inline-block;
}

.el-upload-list__item:hover .el-progress__text {
  display: none;
}

.el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: block;
}

.el-upload-list__item.is-success .el-upload-list__item-name:hover {
  color: #20a0ff;
  cursor: pointer;
}

.el-upload-list__item.is-success:hover .el-upload-list__item-status-label {
  display: none;
}

.el-upload-list__item-name {
  color: #48576a;
  text-overflow: ellipsis;
  margin-right: 40px;
  padding-left: 4px;
  transition: color .3s;
  display: block;
  overflow: hidden;
}

.el-upload-list__item-name [class^="el-icon"] {
  color: #97a8be;
  height: 100%;
  line-height: inherit;
  margin-right: 7px;
}

.el-upload-list__item-status-label {
  line-height: inherit;
  display: none;
  position: absolute;
  top: 0;
  right: 5px;
}

.el-upload-list__item-delete {
  color: #48576a;
  font-size: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

.el-upload-list__item-delete:hover {
  color: #20a0ff;
}

.el-upload-list--picture-card {
  vertical-align: top;
  margin: 0;
  display: inline;
}

.el-upload-list--picture-card .el-upload-list__item {
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  display: inline-block;
  overflow: hidden;
}

.el-upload-list--picture-card .el-upload-list__item .el-icon-check, .el-upload-list--picture-card .el-upload-list__item .el-icon-circle-check {
  color: #fff;
}

.el-upload-list--picture-card .el-upload-list__item .el-icon-close, .el-upload-list--picture-card .el-upload-list__item:hover .el-upload-list__item-status-label {
  display: none;
}

.el-upload-list--picture-card .el-upload-list__item:hover .el-progress__text {
  display: block;
}

.el-upload-list--picture-card .el-upload-list__item-name {
  display: none;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100%;
  height: 100%;
}

.el-upload-list--picture-card .el-upload-list__item-status-label {
  width: 40px;
  height: 24px;
  text-align: center;
  background: #13ce66;
  position: absolute;
  top: -6px;
  right: -15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 0 1pc 1px rgba(0, 0, 0, .2);
}

.el-upload-list--picture-card .el-upload-list__item-status-label i {
  margin-top: 11px;
  font-size: 12px;
  -webkit-transform: rotate(-45deg)scale(.8);
  transform: rotate(-45deg)scale(.8);
}

.el-upload-list--picture-card .el-upload-list__item-actions {
  width: 100%;
  height: 100%;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, .5);
  font-size: 20px;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.el-upload-list--picture-card .el-upload-list__item-actions:after {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.el-upload-list--picture-card .el-upload-list__item-actions span {
  cursor: pointer;
  display: none;
}

.el-upload-list--picture-card .el-upload-list__item-actions span + span {
  margin-left: 15px;
}

.el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete {
  font-size: inherit;
  color: inherit;
  position: static;
}

.el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 1;
}

.el-upload-list--picture-card .el-upload-list__item-actions:hover span {
  display: inline-block;
}

.el-upload-list--picture-card .el-progress {
  width: 126px;
  top: 50%;
  bottom: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.el-upload-list--picture-card .el-progress .el-progress__text {
  top: 50%;
}

.el-upload-list--picture .el-upload-list__item {
  box-sizing: border-box;
  height: 92px;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-top: 10px;
  padding: 10px 10px 10px 90px;
  overflow: hidden;
}

.el-upload-list--picture .el-upload-list__item .el-icon-check, .el-upload-list--picture .el-upload-list__item .el-icon-circle-check {
  color: #fff;
}

.el-upload-list--picture .el-upload-list__item:hover .el-upload-list__item-status-label {
  box-shadow: none;
  background: none;
  top: -2px;
  right: -12px;
}

.el-upload-list--picture .el-upload-list__item:hover .el-progress__text {
  display: block;
}

.el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
  margin-top: 0;
  line-height: 70px;
}

.el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name i {
  display: none;
}

.el-upload-list--picture .el-upload-list__item-thumbnail {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  float: left;
  z-index: 1;
  margin-left: -80px;
  display: inline-block;
  position: relative;
}

.el-upload-list--picture .el-upload-list__item-name {
  margin-top: 20px;
  display: block;
}

.el-upload-list--picture .el-upload-list__item-name i {
  font-size: 70px;
  line-height: 1;
  position: absolute;
  top: 10px;
  left: 9px;
}

.el-upload-list--picture .el-upload-list__item-status-label {
  width: 46px;
  height: 26px;
  text-align: center;
  background: #13ce66;
  position: absolute;
  top: -7px;
  right: -17px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 1px 1px #ccc;
}

.el-upload-list--picture .el-upload-list__item-status-label i {
  margin-top: 12px;
  font-size: 12px;
  -webkit-transform: rotate(-45deg)scale(.8);
  transform: rotate(-45deg)scale(.8);
}

.el-upload-list--picture .el-progress {
  position: relative;
  top: -7px;
}

.el-upload-cover {
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.el-upload-cover:after {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.el-upload-cover img {
  width: 100%;
  height: 100%;
  display: block;
}

.el-upload-cover + .el-upload__inner {
  opacity: 0;
  z-index: 1;
  position: relative;
}

.el-upload-cover__label {
  width: 40px;
  height: 24px;
  text-align: center;
  background: #13ce66;
  position: absolute;
  top: -6px;
  right: -15px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 0 1pc 1px rgba(0, 0, 0, .2);
}

.el-upload-cover__label i {
  color: #fff;
  margin-top: 11px;
  font-size: 12px;
  -webkit-transform: rotate(-45deg)scale(.8);
  transform: rotate(-45deg)scale(.8);
}

.el-upload-cover__progress {
  vertical-align: middle;
  width: 243px;
  display: inline-block;
  position: static;
}

.el-upload-cover__progress + .el-upload__inner {
  opacity: 0;
}

.el-upload-cover__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.el-upload-cover__interact {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, .72);
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-upload-cover__interact .btn {
  color: #fff;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 60px;
  font-size: 14px;
  transition: -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .1s, transform .3s cubic-bezier(.23, 1, .32, 1) .1s, opacity .3s cubic-bezier(.23, 1, .32, 1) .1s;
  display: inline-block;
}

.el-upload-cover__interact .btn span {
  opacity: 0;
  transition: opacity .15s linear;
}

.el-upload-cover__interact .btn:not(:first-child) {
  margin-left: 35px;
}

.el-upload-cover__interact .btn:hover {
  -webkit-transform: translateY(-13px);
  transform: translateY(-13px);
}

.el-upload-cover__interact .btn:hover span {
  opacity: 1;
}

.el-upload-cover__interact .btn i {
  color: #fff;
  font-size: 24px;
  line-height: inherit;
  margin: 0 auto 5px;
  display: block;
}

.el-upload-cover__title {
  height: 36px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: #48576a;
  background-color: #fff;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.el-progress {
  line-height: 1;
  position: relative;
}

.el-progress.is-exception .el-progress-bar__inner {
  background-color: #ff4949;
}

.el-progress.is-exception .el-progress__text {
  color: #ff4949;
}

.el-progress.is-success .el-progress-bar__inner {
  background-color: #13ce66;
}

.el-progress.is-success .el-progress__text {
  color: #13ce66;
}

.el-progress__text {
  color: #48576a;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
}

.el-progress__text i {
  vertical-align: middle;
  display: block;
}

.el-progress--circle {
  display: inline-block;
}

.el-progress--circle .el-progress__text {
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.el-progress--circle .el-progress__text i {
  vertical-align: middle;
  display: inline-block;
}

.el-progress--without-text .el-progress__text {
  display: none;
}

.el-progress--without-text .el-progress-bar {
  margin-right: 0;
  padding-right: 0;
  display: block;
}

.el-progress-bar, .el-progress-bar__innerText, .el-spinner {
  vertical-align: middle;
  display: inline-block;
}

.el-progress--text-inside .el-progress-bar {
  margin-right: 0;
  padding-right: 0;
}

.el-progress-bar {
  width: 100%;
  box-sizing: border-box;
  margin-right: -55px;
  padding-right: 50px;
}

.el-progress-bar__outer {
  height: 6px;
  vertical-align: middle;
  background-color: #e4e8f1;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
}

.el-progress-bar__inner {
  height: 100%;
  text-align: right;
  background-color: #20a0ff;
  border-radius: 100px;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.el-progress-bar__innerText {
  color: #fff;
  margin: 0 5px;
  font-size: 12px;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 32px 0;
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 32px 0;
  }
}

.el-time-spinner {
  width: 100%;
}

.el-spinner-inner {
  width: 50px;
  height: 50px;
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}

.el-spinner-inner .path {
  stroke: #ececec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.el-message {
  min-width: 300px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2px;
  padding: 10px 12px;
  transition: opacity .3s, -webkit-transform .4s, transform .4s;
  position: fixed;
  top: 20px;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-message .el-icon-circle-check {
  color: #13ce66;
}

.el-message .el-icon-circle-cross {
  color: #ff4949;
}

.el-message .el-icon-information {
  color: #50bfff;
}

.el-message .el-icon-warning {
  color: #f7ba2a;
}

.el-message__group {
  height: 20px;
  align-items: center;
  margin-left: 38px;
  line-height: 20px;
  display: flex;
  position: relative;
}

.el-message__group p {
  color: #8391a5;
  text-align: justify;
  margin: 0 34px 0 0;
  font-size: 14px;
}

.el-step__head, .el-steps.is-horizontal.is-center {
  text-align: center;
}

.el-message__group.is-with-icon {
  margin-left: 0;
}

.el-message__img {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.el-message__icon {
  vertical-align: middle;
  margin-right: 8px;
}

.el-message__closeBtn {
  cursor: pointer;
  color: #bfcbd9;
  font-size: 14px;
  position: absolute;
  top: 3px;
  right: 0;
}

.el-message__closeBtn:hover {
  color: #97a8be;
}

.el-message-fade-enter, .el-message-fade-leave-active {
  opacity: 0;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.el-badge {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.el-badge__content {
  color: #fff;
  height: 18px;
  text-align: center;
  background-color: #ff4949;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
}

.el-badge__content.is-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 0;
  right: 0;
}

.el-badge__content.is-fixed {
  position: absolute;
  top: 0;
  right: 10px;
  -webkit-transform: translateY(-50%)translateX(100%);
  transform: translateY(-50%)translateX(100%);
}

.el-rate__icon, .el-rate__item {
  display: inline-block;
  position: relative;
}

.el-badge__content.is-fixed.is-dot {
  right: 5px;
}

.el-card {
  background-color: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-card__header {
  box-sizing: border-box;
  border-bottom: 1px solid #d1dbe5;
  padding: 18px 20px;
}

.el-card__body {
  padding: 20px;
}

.el-rate {
  height: 20px;
  line-height: 1;
}

.el-rate__item {
  vertical-align: middle;
  font-size: 0;
}

.el-rate__icon {
  color: #bfcbd9;
  margin-right: 6px;
  font-size: 18px;
  transition: all .3s;
}

.el-rate__decimal, .el-rate__icon .path2 {
  position: absolute;
  top: 0;
  left: 0;
}

.el-rate__icon.hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.el-rate__decimal {
  display: inline-block;
  overflow: hidden;
}

.el-rate__text {
  vertical-align: middle;
  font-size: 14px;
}

.el-steps {
  font-size: 0;
}

.el-steps > :last-child .el-step__line {
  display: none;
}

.el-step.is-horizontal, .el-step.is-vertical .el-step__head, .el-step.is-vertical .el-step__main, .el-step__line {
  display: inline-block;
}

.el-step {
  vertical-align: top;
  position: relative;
}

.el-step:last-child .el-step__main {
  padding-right: 0;
}

.el-step.is-vertical .el-step__main {
  padding-left: 10px;
}

.el-step__line {
  border-color: inherit;
  background-color: #bfcbd9;
  position: absolute;
}

.el-step__line.is-vertical {
  width: 2px;
  box-sizing: border-box;
  top: 32px;
  bottom: 0;
  left: 15px;
}

.el-step__line.is-horizontal {
  height: 2px;
  top: 15px;
  left: 32px;
  right: 0;
}

.el-step__line.is-icon.is-horizontal {
  right: 4px;
}

.el-step__line-inner {
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  box-sizing: border-box;
  width: 0;
  height: 0;
  transition: all .15s;
  display: block;
}

.el-step__icon {
  line-height: 28px;
  display: block;
}

.el-step__icon > * {
  line-height: inherit;
  vertical-align: middle;
}

.el-step__head {
  width: 28px;
  height: 28px;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  font-size: 28px;
  line-height: 28px;
  transition: all .15s;
}

.el-carousel__arrow, .el-carousel__button {
  cursor: pointer;
  outline: 0;
  margin: 0;
  transition: all .3s;
}

.el-step__head.is-finish {
  color: #20a0ff;
  border-color: #20a0ff;
}

.el-step__head.is-error {
  color: #ff4949;
  border-color: #ff4949;
}

.el-step__head.is-success {
  color: #13ce66;
  border-color: #13ce66;
}

.el-step__head.is-process, .el-step__head.is-wait {
  color: #bfcbd9;
  border-color: #bfcbd9;
}

.el-step__head.is-text {
  border-style: solid;
  border-width: 2px;
  font-size: 14px;
}

.el-step__head.is-text.is-finish {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
}

.el-step__head.is-text.is-error {
  color: #fff;
  background-color: #ff4949;
  border-color: #ff4949;
}

.el-step__head.is-text.is-success {
  color: #fff;
  background-color: #13ce66;
  border-color: #13ce66;
}

.el-step__head.is-text.is-wait {
  color: #bfcbd9;
  background-color: #fff;
  border-color: #bfcbd9;
}

.el-step__head.is-text.is-process {
  color: #fff;
  background-color: #bfcbd9;
  border-color: #bfcbd9;
}

.el-step__main {
  white-space: normal;
  text-align: left;
  padding-right: 10px;
}

.el-step__title {
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
}

.el-step__title.is-finish {
  color: #20a0ff;
  font-weight: 700;
}

.el-step__title.is-error {
  color: #ff4949;
  font-weight: 700;
}

.el-step__title.is-success {
  color: #13ce66;
  font-weight: 700;
}

.el-step__title.is-wait {
  color: #97a8be;
  font-weight: 400;
}

.el-step__title.is-process {
  color: #48576a;
  font-weight: 700;
}

.el-step__description {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.el-step__description.is-finish {
  color: #20a0ff;
}

.el-step__description.is-error {
  color: #ff4949;
}

.el-step__description.is-success {
  color: #13ce66;
}

.el-step__description.is-wait {
  color: #bfcbd9;
}

.el-step__description.is-process {
  color: #8391a5;
}

.el-carousel {
  position: relative;
  overflow-x: hidden;
}

.el-carousel__container {
  height: 300px;
  position: relative;
}

.el-carousel__arrow {
  width: 36px;
  height: 36px;
  color: #fff;
  z-index: 10;
  text-align: center;
  background-color: rgba(31, 45, 61, .11);
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.el-carousel__arrow:hover {
  background-color: rgba(31, 45, 61, .23);
}

.el-carousel__arrow i {
  cursor: pointer;
}

.el-carousel__arrow--left {
  left: 16px;
}

.el-carousel__arrow--right {
  right: 16px;
}

.el-carousel__indicators {
  z-index: 2;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.el-carousel__indicators--outside {
  text-align: center;
  position: static;
  bottom: 26px;
  -webkit-transform: none;
  transform: none;
}

.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  opacity: .64;
}

.el-carousel__indicators--outside button {
  opacity: .24;
  background-color: #8391a5;
}

.el-carousel__indicators--labels {
  text-align: center;
  left: 0;
  right: 0;
  -webkit-transform: none;
  transform: none;
}

.el-carousel__indicators--labels .el-carousel__button {
  width: auto;
  height: auto;
  padding: 2px 18px;
  font-size: 12px;
}

.el-carousel__indicators--labels .el-carousel__indicator {
  padding: 6px 4px;
}

.el-carousel__indicator {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  padding: 12px 4px;
  display: inline-block;
}

.el-carousel__indicator:hover button {
  opacity: .72;
}

.el-carousel__indicator.is-active button {
  opacity: 1;
}

.el-carousel__button {
  opacity: .48;
  width: 30px;
  height: 2px;
  background-color: #fff;
  border: none;
  padding: 0;
  display: block;
}

.carousel-arrow-left-enter, .carousel-arrow-left-leave-active {
  opacity: 0;
  -webkit-transform: translateY(-50%)translateX(-10px);
  transform: translateY(-50%)translateX(-10px);
}

.carousel-arrow-right-enter, .carousel-arrow-right-leave-active {
  opacity: 0;
  -webkit-transform: translateY(-50%)translateX(10px);
  transform: translateY(-50%)translateX(10px);
}

.el-scrollbar {
  position: relative;
  overflow: hidden;
}

.el-scrollbar:active .el-scrollbar__bar, .el-scrollbar:focus .el-scrollbar__bar, .el-scrollbar:hover .el-scrollbar__bar {
  opacity: 1;
  transition: opacity .34s ease-out;
}

.el-scrollbar__wrap {
  overflow: scroll;
}

.el-scrollbar__wrap--hidden-default::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.el-scrollbar__thumb {
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(151, 168, 190, .3);
  transition: background-color .3s;
  display: block;
  position: relative;
}

.el-scrollbar__thumb:hover {
  background-color: rgba(151, 168, 190, .5);
}

.el-scrollbar__bar {
  z-index: 1;
  opacity: 0;
  border-radius: 4px;
  transition: opacity .12s ease-out;
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.el-carousel__item--card, .el-carousel__item.is-animating {
  transition: -webkit-transform .4s ease-in-out, transform .4s ease-in-out;
}

.el-scrollbar__bar.is-horizontal {
  height: 6px;
  left: 2px;
}

.el-scrollbar__bar.is-horizontal > div {
  height: 100%;
}

.el-scrollbar__bar.is-vertical {
  width: 6px;
  top: 2px;
}

.el-scrollbar__bar.is-vertical > div {
  width: 100%;
}

.el-carousel__item {
  width: 100%;
  height: 100%;
  z-index: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.el-carousel__item.is-active {
  z-index: 2;
}

.el-carousel__item--card {
  width: 50%;
}

.el-carousel__item--card.is-in-stage {
  cursor: pointer;
  z-index: 1;
}

.el-carousel__item--card.is-active, .el-cascader .el-icon-circle-close, .el-cascader-menus {
  z-index: 2;
}

.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
  opacity: .12;
}

.el-carousel__mask {
  width: 100%;
  height: 100%;
  opacity: .24;
  background-color: #fff;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.el-collapse {
  border: 1px solid #dfe6ec;
  border-radius: 0;
}

.el-collapse-item:last-child {
  margin-bottom: -1px;
}

.el-collapse-item.is-active > .el-collapse-item__header .el-collapse-item__header__arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.el-collapse-item__header {
  height: 43px;
  color: #48576a;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #dfe6ec;
  padding-left: 15px;
  font-size: 13px;
  line-height: 43px;
}

.el-collapse-item__header__arrow {
  margin-right: 8px;
  transition: -webkit-transform .3s, transform .3s;
}

.el-collapse-item__wrap {
  will-change: height;
  box-sizing: border-box;
  background-color: #fbfdff;
  border-bottom: 1px solid #dfe6ec;
  overflow: hidden;
}

.el-collapse-item__content {
  color: #1f2d3d;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.76923;
}

.el-cascader {
  display: inline-block;
  position: relative;
}

.el-cascader .el-input, .el-cascader .el-input__inner {
  cursor: pointer;
}

.el-cascader .el-input__icon {
  transition: none;
}

.el-cascader .el-icon-caret-bottom {
  transition: -webkit-transform .3s, transform .3s;
}

.el-cascader .el-icon-caret-bottom.is-reverse {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.el-cascader.is-disabled .el-cascader__label {
  z-index: 2;
  color: #bbb;
}

.el-cascader__label {
  height: 100%;
  color: #1f2d3d;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  padding: 0 25px 0 10px;
  font-size: 14px;
  line-height: 36px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.el-cascader__label span {
  color: #97a8be;
}

.el-cascader--large {
  font-size: 16px;
}

.el-cascader--large .el-cascader__label {
  line-height: 40px;
}

.el-cascader--small {
  font-size: 13px;
}

.el-cascader--small .el-cascader__label {
  line-height: 28px;
}

.el-cascader-menus {
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d1dbe5;
  border-radius: 2px;
  margin: 5px 0;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-cascader-menu {
  vertical-align: top;
  height: 204px;
  box-sizing: border-box;
  min-width: 160px;
  background-color: #fff;
  border-right: 1px solid #d1dbe5;
  margin: 0;
  padding: 6px 0;
  display: inline-block;
  overflow: auto;
}

.el-cascader-menu:last-child {
  border-right: 0;
}

.el-cascader-menu__item {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #48576a;
  height: 36px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 30px 8px 10px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  overflow: hidden;
}

.el-cascader-menu__item:hover {
  background-color: #e4e8f1;
}

.el-cascader-menu__item.selected {
  color: #fff;
  background-color: #20a0ff;
}

.el-cascader-menu__item.selected.hover {
  background-color: #1c8de0;
}

.el-cascader-menu__item.is-active {
  color: #fff;
  background-color: #20a0ff;
}

.el-cascader-menu__item.is-active:hover {
  background-color: #1c8de0;
}

.el-cascader-menu__item.is-disabled {
  color: #bfcbd9;
  cursor: not-allowed;
  background-color: #fff;
}

.el-cascader-menu__item.is-disabled:hover {
  background-color: #fff;
}

.el-cascader-menu__item__keyword {
  font-weight: 700;
}

.el-cascader-menu__item--extensible:after {
  content: "";
  color: #bfcbd9;
  margin-top: 1px;
  font-family: element-icons;
  font-size: 12px;
  position: absolute;
  right: 10px;
  -webkit-transform: scale(.8);
  transform: scale(.8);
}

.el-cascader-menu--flexible {
  height: auto;
  max-height: 180px;
  overflow: auto;
}

.el-cascader-menu--flexible .el-cascader-menu__item {
  overflow: visible;
}

.el-color-hue-slider {
  box-sizing: border-box;
  width: 280px;
  height: 12px;
  background-color: red;
  padding: 0 2px;
  position: relative;
}

.el-color-hue-slider.is-vertical {
  width: 12px;
  height: 180px;
  padding: 2px 0;
}

.el-color-hue-slider.is-vertical .el-color-hue-slider__bar {
  background: linear-gradient(red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%);
}

.el-color-hue-slider.is-vertical .el-color-hue-slider__thumb {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.el-color-hue-slider__bar {
  height: 100%;
  background: linear-gradient(to right, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%);
  position: relative;
}

.el-color-hue-slider__thumb {
  cursor: pointer;
  box-sizing: border-box;
  width: 4px;
  height: 100%;
  z-index: 1;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, .6);
}

.el-color-svpanel {
  width: 280px;
  height: 180px;
  position: relative;
}

.el-color-svpanel__black, .el-color-svpanel__white {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-color-svpanel__white {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.el-color-svpanel__black {
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
}

.el-color-svpanel__cursor {
  position: absolute;
}

.el-color-svpanel__cursor > div {
  cursor: head;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-transform: translate(-2px, -2px);
  transform: translate(-2px, -2px);
  box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, .3), 0 0 1px 2px rgba(0, 0, 0, .4);
}

.el-color-alpha-slider {
  box-sizing: border-box;
  width: 280px;
  height: 12px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
  position: relative;
}

.el-color-alpha-slider.is-vertical {
  width: 20px;
  height: 180px;
}

.el-color-alpha-slider.is-vertical .el-color-alpha-slider__bar {
  background: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 100%);
}

.el-color-alpha-slider.is-vertical .el-color-alpha-slider__thumb {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.el-color-alpha-slider__bar {
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 100%);
  position: relative;
}

.el-color-alpha-slider__thumb {
  cursor: pointer;
  box-sizing: border-box;
  width: 4px;
  height: 100%;
  z-index: 1;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, .6);
}

.el-color-dropdown {
  width: 300px;
}

.el-color-dropdown__main-wrapper {
  margin-bottom: 6px;
}

.el-color-dropdown__main-wrapper:after {
  content: "";
  display: table;
}

.el-color-dropdown__btns {
  text-align: right;
  margin-top: 6px;
}

.el-color-dropdown__value {
  float: left;
  color: #1f2d3d;
  font-size: 12px;
  line-height: 26px;
}

.el-color-dropdown__btn {
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  outline: 0;
  padding: 0 20px;
  font-size: 12px;
  line-height: 24px;
}

.el-color-dropdown__btn[disabled] {
  color: #ccc;
  cursor: not-allowed;
}

.el-color-dropdown__btn:hover {
  color: #20a0ff;
  border-color: #20a0ff;
}

.el-color-dropdown__link-btn {
  cursor: pointer;
  color: #20a0ff;
  padding: 15px;
  font-size: 12px;
  text-decoration: none;
}

.el-color-dropdown__link-btn:hover {
  color: #4db3ff;
}

.el-color-picker {
  line-height: normal;
  display: inline-block;
  position: relative;
}

.el-color-picker__trigger {
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  padding: 6px;
  font-size: 0;
  display: inline-block;
}

.el-color-picker__color {
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  text-align: center;
  border: 1px solid #666;
  display: inline-block;
  position: relative;
}

.el-color-picker__color.is-alpha {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}

.el-color-picker__color-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-color-picker__empty {
  vertical-align: middle;
  color: #666;
  font-size: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.el-color-picker__icon {
  width: 12px;
  color: #888;
  margin-left: 8px;
  font-size: 12px;
  display: inline-block;
  position: relative;
  top: -6px;
}

.el-input, .el-input__inner {
  width: 100%;
  display: inline-block;
}

.el-color-picker__panel {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #d1dbe5;
  padding: 6px;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .12);
}

.el-input {
  font-size: 14px;
  position: relative;
}

.el-input.is-disabled .el-input__inner {
  color: #bbb;
  cursor: not-allowed;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  color: #bfcbd9;
}

.el-input.is-disabled .el-input__inner::-moz-placeholder {
  color: #bfcbd9;
}

.el-input.is-disabled .el-input__inner:-ms-input-placeholder {
  color: #bfcbd9;
}

.el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  color: #bfcbd9;
}

.el-input.is-disabled .el-input__inner::placeholder {
  color: #bfcbd9;
}

.el-input.is-active .el-input__inner {
  border-color: #20a0ff;
  outline: 0;
}

.el-input__inner {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: #1f2d3d;
  font-size: inherit;
  height: 36px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  outline: 0;
  padding: 3px 10px;
  line-height: 1;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.el-button, .el-checkbox-button__inner {
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: center;
  outline: 0;
}

.el-input__inner::-webkit-input-placeholder {
  color: #97a8be;
}

.el-input__inner::-moz-placeholder {
  color: #97a8be;
}

.el-input__inner:-ms-input-placeholder {
  color: #97a8be;
}

.el-input__inner::-webkit-input-placeholder {
  color: #97a8be;
}

.el-input__inner::placeholder {
  color: #97a8be;
}

.el-input__inner:hover {
  border-color: #8391a5;
}

.el-input__inner:focus {
  border-color: #20a0ff;
  outline: 0;
}

.el-input__icon {
  width: 35px;
  height: 100%;
  text-align: center;
  color: #bfcbd9;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.el-input__icon:after {
  content: "";
  height: 100%;
  width: 0;
  vertical-align: middle;
  display: inline-block;
}

.el-input__icon + .el-input__inner {
  padding-right: 35px;
}

.el-input__icon.is-clickable:hover {
  cursor: pointer;
  color: #8391a5;
}

.el-input__icon.is-clickable:hover + .el-input__inner {
  border-color: #8391a5;
}

.el-input--large {
  font-size: 16px;
}

.el-input--large .el-input__inner {
  height: 42px;
}

.el-input--small {
  font-size: 13px;
}

.el-input--small .el-input__inner {
  height: 30px;
}

.el-input--mini {
  font-size: 12px;
}

.el-input--mini .el-input__inner {
  height: 22px;
}

.el-input-group {
  width: 100%;
  border-collapse: separate;
  line-height: normal;
  display: inline-table;
}

.el-input-group > .el-input__inner {
  vertical-align: middle;
  display: table-cell;
}

.el-input-group__append, .el-input-group__prepend {
  color: #97a8be;
  vertical-align: middle;
  width: 1px;
  white-space: nowrap;
  background-color: #fbfdff;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  padding: 0 10px;
  display: table-cell;
  position: relative;
}

.el-input-group--prepend .el-input__inner, .el-input-group__append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.el-input-group--append .el-input__inner, .el-input-group__prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.el-input-group__append .el-button, .el-input-group__append .el-select, .el-input-group__prepend .el-button, .el-input-group__prepend .el-select {
  margin: -10px;
  display: block;
}

.el-input-group__append button.el-button, .el-input-group__append div.el-select .el-input__inner, .el-input-group__append div.el-select:hover .el-input__inner, .el-input-group__prepend button.el-button, .el-input-group__prepend div.el-select .el-input__inner, .el-input-group__prepend div.el-select:hover .el-input__inner {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-color: currentColor rgba(0, 0, 0, 0);
  border-top-style: none;
  border-top-width: 0;
  border-bottom-style: none;
  border-bottom-width: 0;
}

.el-input-group__append .el-button, .el-input-group__append .el-input, .el-input-group__prepend .el-button, .el-input-group__prepend .el-input {
  font-size: inherit;
}

.el-button, .el-textarea__inner {
  box-sizing: border-box;
  font-size: 14px;
}

.el-input-group__prepend {
  border-right: 0;
}

.el-input-group__append {
  border-left: 0;
}

.el-textarea {
  width: 100%;
  vertical-align: bottom;
  display: inline-block;
}

.el-textarea.is-disabled .el-textarea__inner {
  color: #bbb;
  cursor: not-allowed;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.el-textarea.is-disabled .el-textarea__inner::-webkit-input-placeholder {
  color: #bfcbd9;
}

.el-textarea.is-disabled .el-textarea__inner::-moz-placeholder {
  color: #bfcbd9;
}

.el-textarea.is-disabled .el-textarea__inner:-ms-input-placeholder {
  color: #bfcbd9;
}

.el-textarea.is-disabled .el-textarea__inner::-webkit-input-placeholder {
  color: #bfcbd9;
}

.el-textarea.is-disabled .el-textarea__inner::placeholder {
  color: #bfcbd9;
}

.el-textarea__inner {
  resize: vertical;
  width: 100%;
  color: #1f2d3d;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  padding: 5px 7px;
  line-height: 1.5;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  display: block;
}

.el-textarea__inner::-webkit-input-placeholder {
  color: #97a8be;
}

.el-textarea__inner::-moz-placeholder {
  color: #97a8be;
}

.el-textarea__inner:-ms-input-placeholder {
  color: #97a8be;
}

.el-textarea__inner::-webkit-input-placeholder {
  color: #97a8be;
}

.el-textarea__inner::placeholder {
  color: #97a8be;
}

.el-textarea__inner:hover {
  border-color: #8391a5;
}

.el-textarea__inner:focus {
  border-color: #20a0ff;
  outline: 0;
}

.el-button {
  white-space: nowrap;
  cursor: pointer;
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin: 0;
  padding: 10px 15px;
  line-height: 1;
  display: inline-block;
}

.el-button + .el-button {
  margin-left: 10px;
}

.el-button:focus, .el-button:hover {
  color: #20a0ff;
  border-color: #20a0ff;
}

.el-button:active {
  color: #1d90e6;
  border-color: #1d90e6;
  outline: 0;
}

.el-button::-moz-focus-inner {
  border: 0;
}

.el-button [class*="el-icon-"] + span {
  margin-left: 5px;
}

.el-button.is-loading {
  pointer-events: none;
  position: relative;
}

.el-button.is-loading:before {
  pointer-events: none;
  content: "";
  border-radius: inherit;
  background-color: rgba(255, 255, 255, .35);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
  color: #bfcbd9;
  cursor: not-allowed;
  background-color: #eef1f6;
  background-image: none;
  border-color: #d1dbe5;
}

.el-checkbox, .el-checkbox__input {
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.el-button.is-disabled.el-button--text {
  background-color: rgba(0, 0, 0, 0);
}

.el-button.is-disabled.is-plain, .el-button.is-disabled.is-plain:focus, .el-button.is-disabled.is-plain:hover {
  color: #bfcbd9;
  background-color: #fff;
  border-color: #d1dbe5;
}

.el-button.is-active {
  color: #1d90e6;
  border-color: #1d90e6;
}

.el-button.is-plain:focus, .el-button.is-plain:hover {
  color: #20a0ff;
  background: #fff;
  border-color: #20a0ff;
}

.el-button.is-plain:active {
  color: #1d90e6;
  background: #fff;
  border-color: #1d90e6;
  outline: 0;
}

.el-button--primary {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
}

.el-button--primary:focus, .el-button--primary:hover {
  color: #fff;
  background: #4db3ff;
  border-color: #4db3ff;
}

.el-button--primary.is-active, .el-button--primary:active {
  color: #fff;
  background: #1d90e6;
  border-color: #1d90e6;
}

.el-button--primary:active {
  outline: 0;
}

.el-button--primary.is-plain {
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #bfcbd9;
}

.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover {
  color: #20a0ff;
  background: #fff;
  border-color: #20a0ff;
}

.el-button--primary.is-plain:active {
  color: #1d90e6;
  background: #fff;
  border-color: #1d90e6;
  outline: 0;
}

.el-button--success {
  color: #fff;
  background-color: #13ce66;
  border-color: #13ce66;
}

.el-button--success:focus, .el-button--success:hover {
  color: #fff;
  background: #42d885;
  border-color: #42d885;
}

.el-button--success.is-active, .el-button--success:active {
  color: #fff;
  background: #11b95c;
  border-color: #11b95c;
}

.el-button--success:active {
  outline: 0;
}

.el-button--success.is-plain {
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #bfcbd9;
}

.el-button--success.is-plain:focus, .el-button--success.is-plain:hover {
  color: #13ce66;
  background: #fff;
  border-color: #13ce66;
}

.el-button--success.is-plain:active {
  color: #11b95c;
  background: #fff;
  border-color: #11b95c;
  outline: 0;
}

.el-button--warning {
  color: #fff;
  background-color: #f7ba2a;
  border-color: #f7ba2a;
}

.el-button--warning:focus, .el-button--warning:hover {
  color: #fff;
  background: #f9c855;
  border-color: #f9c855;
}

.el-button--warning.is-active, .el-button--warning:active {
  color: #fff;
  background: #dea726;
  border-color: #dea726;
}

.el-button--warning:active {
  outline: 0;
}

.el-button--warning.is-plain {
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #bfcbd9;
}

.el-button--warning.is-plain:focus, .el-button--warning.is-plain:hover {
  color: #f7ba2a;
  background: #fff;
  border-color: #f7ba2a;
}

.el-button--warning.is-plain:active {
  color: #dea726;
  background: #fff;
  border-color: #dea726;
  outline: 0;
}

.el-button--danger {
  color: #fff;
  background-color: #ff4949;
  border-color: #ff4949;
}

.el-button--danger:focus, .el-button--danger:hover {
  color: #fff;
  background: #ff6d6d;
  border-color: #ff6d6d;
}

.el-button--danger.is-active, .el-button--danger:active {
  color: #fff;
  background: #e64242;
  border-color: #e64242;
}

.el-button--danger:active {
  outline: 0;
}

.el-button--danger.is-plain {
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #bfcbd9;
}

.el-button--danger.is-plain:focus, .el-button--danger.is-plain:hover {
  color: #ff4949;
  background: #fff;
  border-color: #ff4949;
}

.el-button--danger.is-plain:active {
  color: #e64242;
  background: #fff;
  border-color: #e64242;
  outline: 0;
}

.el-button--info {
  color: #fff;
  background-color: #50bfff;
  border-color: #50bfff;
}

.el-button--info:focus, .el-button--info:hover {
  color: #fff;
  background: #73ccff;
  border-color: #73ccff;
}

.el-button--info.is-active, .el-button--info:active {
  color: #fff;
  background: #48ace6;
  border-color: #48ace6;
}

.el-button--info:active {
  outline: 0;
}

.el-button--info.is-plain {
  color: #1f2d3d;
  background: #fff;
  border: 1px solid #bfcbd9;
}

.el-button--info.is-plain:focus, .el-button--info.is-plain:hover {
  color: #50bfff;
  background: #fff;
  border-color: #50bfff;
}

.el-button--info.is-plain:active {
  color: #48ace6;
  background: #fff;
  border-color: #48ace6;
  outline: 0;
}

.el-button--large {
  border-radius: 4px;
  padding: 11px 19px;
  font-size: 16px;
}

.el-button--small {
  border-radius: 4px;
  padding: 7px 9px;
  font-size: 12px;
}

.el-button--mini {
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}

.el-button--text {
  color: #20a0ff;
  background: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.el-button--text:focus, .el-button--text:hover {
  color: #4db3ff;
}

.el-button--text:active {
  color: #1d90e6;
}

.el-button-group {
  vertical-align: middle;
  display: inline-block;
}

.el-button-group .el-button--primary:first-child {
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--primary:last-child {
  border-left-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--primary:not(:first-child):not(:last-child) {
  border-left-color: rgba(255, 255, 255, .5);
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--success:first-child {
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--success:last-child {
  border-left-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--success:not(:first-child):not(:last-child) {
  border-left-color: rgba(255, 255, 255, .5);
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--warning:first-child {
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--warning:last-child {
  border-left-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--warning:not(:first-child):not(:last-child) {
  border-left-color: rgba(255, 255, 255, .5);
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--danger:first-child {
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--danger:last-child {
  border-left-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--danger:not(:first-child):not(:last-child) {
  border-left-color: rgba(255, 255, 255, .5);
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--info:first-child {
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--info:last-child {
  border-left-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button--info:not(:first-child):not(:last-child) {
  border-left-color: rgba(255, 255, 255, .5);
  border-right-color: rgba(255, 255, 255, .5);
}

.el-button-group .el-button {
  float: left;
  position: relative;
}

.el-button-group .el-button + .el-button {
  margin-left: 0;
}

.el-button-group .el-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.el-button-group .el-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.el-button-group .el-button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.el-button-group .el-button:not(:last-child) {
  margin-right: -1px;
}

.el-button-group .el-button.is-active, .el-button-group .el-button:active, .el-button-group .el-button:focus, .el-button-group .el-button:hover {
  z-index: 1;
}

.el-checkbox {
  color: #1f2d3d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.el-checkbox + .el-checkbox {
  margin-left: 15px;
}

.el-checkbox__input {
  vertical-align: middle;
  outline: 0;
  line-height: 1;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #20a0ff;
  border-color: #0190fe;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
  content: "";
  border: 1px solid #fff;
  margin-top: -1px;
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
  right: 3px;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner:after {
  display: none;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #20a0ff;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #20a0ff;
  border-color: #0190fe;
}

.el-checkbox__input.is-checked .el-checkbox__inner:after {
  -webkit-transform: rotate(45deg)scaleY(1);
  transform: rotate(45deg)scaleY(1);
}

.el-checkbox__input.is-disabled .el-checkbox__inner {
  cursor: not-allowed;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

.el-checkbox__input.is-disabled .el-checkbox__inner:after {
  cursor: not-allowed;
  border-color: #eef1f6;
}

.el-checkbox__input.is-disabled .el-checkbox__inner + .el-checkbox__label {
  cursor: not-allowed;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #d1dbe5;
  border-color: #d1dbe5;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner:after {
  border-color: #fff;
}

.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner {
  background-color: #d1dbe5;
  border-color: #d1dbe5;
}

.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner:before {
  border-color: #fff;
}

.el-checkbox__input.is-disabled + .el-checkbox__label {
  color: #bbb;
  cursor: not-allowed;
}

.el-checkbox__inner {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #bfcbd9;
  border-radius: 4px;
  transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
  display: inline-block;
  position: relative;
}

.el-checkbox__inner:hover {
  border-color: #20a0ff;
}

.el-checkbox__inner:after {
  box-sizing: content-box;
  content: "";
  height: 8px;
  width: 4px;
  -webkit-transform-origin: center;
  transform-origin: center;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: -webkit-transform .15s cubic-bezier(.71, -.46, .88, .6) 50ms, transform .15s cubic-bezier(.71, -.46, .88, .6) 50ms;
  position: absolute;
  top: 1px;
  left: 5px;
  -webkit-transform: rotate(45deg)scaleY(0);
  transform: rotate(45deg)scaleY(0);
}

.el-checkbox__original {
  opacity: 0;
  width: 0;
  height: 0;
  outline: 0;
  margin: 0;
  position: absolute;
  left: -999px;
}

.el-checkbox-button, .el-checkbox-button__inner {
  display: inline-block;
  position: relative;
}

.el-checkbox__label {
  padding-left: 5px;
  font-size: 14px;
}

.el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  box-shadow: -1px 0 #20a0ff;
}

.el-checkbox-button.is-disabled .el-checkbox-button__inner {
  color: #bfcbd9;
  cursor: not-allowed;
  box-shadow: none;
  background-color: #eef1f6;
  background-image: none;
  border-color: #d1dbe5;
}

.el-checkbox-button__inner, .el-transfer-panel {
  vertical-align: middle;
  box-sizing: border-box;
  background: #fff;
}

.el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #20a0ff;
}

.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-left: 1px solid #bfcbd9;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}

.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0 4px 4px 0;
}

.el-checkbox-button__inner {
  white-space: nowrap;
  color: #1f2d3d;
  cursor: pointer;
  border: 1px solid #bfcbd9;
  border-left: 0;
  border-radius: 0;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.el-checkbox-button__inner:hover {
  color: #20a0ff;
}

.el-checkbox-button__inner [class*="el-icon-"] {
  line-height: .9;
}

.el-checkbox-button__inner [class*="el-icon-"] + span {
  margin-left: 5px;
}

.el-checkbox-button__original {
  opacity: 0;
  outline: 0;
  margin: 0;
  position: absolute;
  left: -999px;
}

.el-checkbox-button--large .el-checkbox-button__inner {
  border-radius: 0;
  padding: 11px 19px;
  font-size: 16px;
}

.el-checkbox-button--small .el-checkbox-button__inner {
  border-radius: 0;
  padding: 7px 9px;
  font-size: 12px;
}

.el-checkbox-button--mini .el-checkbox-button__inner {
  border-radius: 0;
  padding: 4px;
  font-size: 12px;
}

.el-transfer {
  font-size: 14px;
}

.el-transfer__buttons {
  vertical-align: middle;
  padding: 0 10px;
  display: inline-block;
}

.el-transfer__buttons .el-button {
  margin: 0 auto;
  padding: 8px 12px;
  display: block;
}

.el-transfer-panel__item + .el-transfer-panel__item, .el-transfer__buttons .el-button [class*="el-icon-"] + span {
  margin-left: 0;
}

.el-transfer__buttons .el-button:first-child {
  margin-bottom: 6px;
}

.el-transfer-panel {
  width: 200px;
  border: 1px solid #d1dbe5;
  display: inline-block;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.el-transfer-panel .el-transfer-panel__header {
  height: 36px;
  box-sizing: border-box;
  color: #1f2d3d;
  background: #fbfdff;
  border-bottom: 1px solid #d1dbe5;
  margin: 0;
  padding-left: 20px;
  line-height: 36px;
}

.el-transfer-panel .el-transfer-panel__footer {
  height: 36px;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-top: 1px solid #d1dbe5;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.el-transfer-panel .el-transfer-panel__footer:after {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.el-transfer-panel .el-transfer-panel__footer .el-checkbox {
  color: #8391a5;
  padding-left: 20px;
}

.el-transfer-panel .el-transfer-panel__empty {
  height: 32px;
  color: #8391a5;
  margin: 0;
  padding: 6px 20px 0;
  line-height: 32px;
}

.el-transfer-panel .el-checkbox__label {
  padding-left: 14px;
}

.el-transfer-panel .el-checkbox__inner {
  width: 14px;
  height: 14px;
  border-radius: 3px;
}

.el-transfer-panel .el-checkbox__inner:after {
  height: 6px;
  width: 3px;
  left: 4px;
}

.el-transfer-panel__body {
  height: 246px;
  padding-bottom: 36px;
}

.el-transfer-panel__list {
  height: 246px;
  box-sizing: border-box;
  margin: 0;
  padding: 6px 0;
  list-style: none;
  overflow: auto;
}

.el-transfer-panel__list.is-filterable {
  height: 214px;
}

.el-transfer-panel__item {
  height: 32px;
  padding-left: 20px;
  line-height: 32px;
  display: block;
}

.el-transfer-panel__item .el-checkbox__label {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding-left: 28px;
  display: block;
  overflow: hidden;
}

.el-transfer-panel__item .el-checkbox__input {
  position: absolute;
  top: 9px;
}

.el-transfer-panel__item.el-checkbox {
  color: #48576a;
}

.el-transfer-panel__item:hover {
  background: #e4e8f1;
}

.el-transfer-panel__filter {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0 10px;
}

.el-transfer-panel__filter .el-input__inner {
  height: 22px;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

.el-transfer-panel__filter .el-input__icon {
  right: 10px;
}

.el-transfer-panel__filter .el-icon-circle-close {
  cursor: pointer;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*, :after, :before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0;
}

hr:not([size]) {
  height: 1px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}

.h2, h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  .h2, h2 {
    font-size: 2rem;
  }
}

.h3, h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  .h3, h3 {
    font-size: 1.75rem;
  }
}

.h4, h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  .h4, h4 {
    font-size: 1.5rem;
  }
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-bs-original-title], abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

.small, small {
  font-size: .875em;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  direction: ltr;
  unicode-bidi: bidi-override;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: #d63384;
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y) );
  margin-right: calc(-.5 * var(--bs-gutter-x) );
  margin-left: calc(-.5 * var(--bs-gutter-x) );
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, .05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, .1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, .075);
  width: 100%;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:first-child) {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #212529;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:dir(ltr)::-webkit-file-upload-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:dir(rtl)::-webkit-file-upload-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-radius: 0;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:dir(ltr)::-webkit-file-upload-button {
  border-right-width: 1px;
  margin-right: .75rem;
}

.form-control:dir(rtl)::-webkit-file-upload-button {
  border-left-width: 1px;
  margin-left: .75rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:dir(ltr)::-webkit-file-upload-button {
  margin-right: .5rem;
}

.form-control-sm:dir(rtl)::-webkit-file-upload-button {
  margin-left: .5rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: .5rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: .5rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .5rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .5rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  padding: .25rem .5rem;
}

.form-control-sm:dir(ltr)::-webkit-file-upload-button {
  margin-right: .5rem;
}

.form-control-sm:dir(rtl)::-webkit-file-upload-button {
  margin-left: .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg:dir(ltr)::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-lg:dir(rtl)::-webkit-file-upload-button {
  margin-left: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-lg:dir(ltr)::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-lg:dir(rtl)::-webkit-file-upload-button {
  margin-left: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: .25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: .25rem;
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .2rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .3rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .5;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #dee2e6;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #dee2e6;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  height: 100%;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem .75rem;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  -webkit-transform: scale(.85)translateY(-.5rem)translateX(.15rem);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.valid-feedback {
  width: 100%;
  color: #198754;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(25, 135, 84, .9);
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #198754;
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #198754;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #198754;
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25);
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 1;
}

.input-group .form-control.is-valid:focus, .input-group .form-select.is-valid:focus, .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(220, 53, 69, .9);
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #dc3545;
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25);
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
  z-index: 2;
}

.input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus, .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5);
}

.btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5);
}

.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5);
}

.btn-check:active + .btn-info, .btn-check:checked + .btn-info, .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5);
}

.btn-check:active + .btn-warning, .btn-check:checked + .btn-warning, .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5);
}

.btn-check:active + .btn-danger, .btn-check:checked + .btn-danger, .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
}

.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5);
}

.btn-check:active + .btn-dark, .btn-check:checked + .btn-dark, .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5);
}

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5);
}

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5);
}

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #198754;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5);
}

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
}

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5);
}

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5);
}

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: rgba(0, 0, 0, 0);
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5);
}

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: rgba(0, 0, 0, 0);
}

.btn-link {
  color: #0d6efd;
  font-weight: 400;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropdown, .dropend, .dropstart, .dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: .125rem;
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, .15);
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: .25rem 1rem;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1rem;
  display: block;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, .15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, .15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  color: #0d6efd;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus, .nav-link:hover {
  color: #0a58ca;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  font-size: 1.25rem;
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 .25rem;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-sm .offcanvas-bottom, .navbar-expand-sm .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-md .offcanvas-bottom, .navbar-expand-md .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-lg .offcanvas-bottom, .navbar-expand-lg .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xl .offcanvas-bottom, .navbar-expand-xl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0);
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    -webkit-transform: none;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xxl .offcanvas-bottom, .navbar-expand-xxl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler, .navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  border-left: 0;
  border-right: 0;
  flex-grow: 1;
  transition: none;
  bottom: 0;
  -webkit-transform: none;
  transform: none;
  visibility: visible !important;
}

.navbar-expand .offcanvas-bottom, .navbar-expand .offcanvas-top {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .55);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, .55);
  border-color: rgba(0, 0, 0, .1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, .55);
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, .9);
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .55);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, .75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, .55);
  border-color: rgba(255, 255, 255, .1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, .55);
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: .5rem;
}

.card-subtitle {
  margin-top: -.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  margin-bottom: 0;
  padding: .5rem 1rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125);
  padding: .5rem 1rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.5rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.card-header-pills {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-group > .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  width: 100%;
  color: #212529;
  text-align: left;
  overflow-anchor: none;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  align-items: center;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-button:after {
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  flex-shrink: 0;
  margin-left: auto;
  transition: -webkit-transform .2s ease-in-out, transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
}

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
  padding-right: .5rem;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #0d6efd;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: .375rem .75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > li:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
  padding: .5rem 1rem;
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .25rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  -webkit-filter: invert() grayscale() brightness(200%);
  filter: invert() grayscale() brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .25rem;
  font-size: .875rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: .75rem;
}

.toast-header {
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .5rem .75rem;
  display: flex;
}

.toast-header .btn-close {
  margin-left: .75rem;
  margin-right: -.375rem;
}

.toast-body {
  word-wrap: break-word;
  padding: .75rem;
}

.modal {
  z-index: 1055;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-header .btn-close {
  margin: -.5rem -.5rem -.5rem auto;
  padding: .5rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: calc(.3rem - 1px);
  border-bottom-right-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  z-index: 1080;
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.tooltip:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .tooltip-arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.bs-tooltip-auto[data-popper-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: -1px;
}

.bs-tooltip-auto[data-popper-placement^="right"], .bs-tooltip-end {
  padding: 0 .4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: -1px;
}

.bs-tooltip-auto[data-popper-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: -1px;
}

.bs-tooltip-auto[data-popper-placement^="left"], .bs-tooltip-start {
  padding: 0 .4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: -1px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1070;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.popover:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover .popover-arrow {
  width: 1rem;
  height: .5rem;
  display: block;
  position: absolute;
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25);
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .bs-popover-top > .popover-arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow, .bs-popover-end > .popover-arrow {
  width: .5rem;
  height: 1rem;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25);
  left: 0;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .bs-popover-end > .popover-arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25);
  top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow, .bs-popover-start > .popover-arrow {
  width: .5rem;
  height: 1rem;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25);
  right: 0;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .bs-popover-start > .popover-arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: 1rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: -webkit-transform .6s ease-in-out, transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert() grayscale(100);
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.offcanvas {
  z-index: 1045;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: -webkit-transform .3s ease-in-out, transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.offcanvas-header .btn-close {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
  margin-right: -.5rem;
  padding: .5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  top: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  left: 0;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.link-primary {
  color: #0d6efd;
}

.link-primary:focus, .link-primary:hover {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:focus, .link-secondary:hover {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:focus, .link-success:hover {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:focus, .link-info:hover {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:focus, .link-warning:hover {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:focus, .link-danger:hover {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:focus, .link-light:hover {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}

.link-dark:focus, .link-dark:hover {
  color: #1a1e21;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .176) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity) ) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity) ) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity) ) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity) ) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity) ) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity) ) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity) ) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity) ) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity) ) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity) ) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity) ) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, .5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity) ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .2rem !important;
}

.rounded-2 {
  border-radius: .25rem !important;
}

.rounded-3 {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-end {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-start {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

body {
  --bs-font-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --bs-body-font-family: var(--bs-font-sans-serif);
}

header a.moniker {
  margin-left: 15px;
}

.clipboard.copied {
  text-decoration: underline;
}

.btn-circle-sm {
  width: 30px;
  height: 30px;
  font-size: .9rem;
  line-height: 30px;
}

.btn-circle-lg {
  width: 40px;
  height: 40px;
  font-size: 1.1rem;
  line-height: 40px;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  font-size: 1.3rem;
  line-height: 70px;
}

code {
  padding: .5rem;
  line-height: 1.5rem;
}

code p {
  margin-bottom: .5rem;
}

.favourite-on .on, .favourite-off .off {
  display: inline;
}

.favourite-on .off, .favourite-off .on {
  display: none;
}

.fs-6small {
  font-size: 15px !important;
}

.fs-1_2 {
  font-size: 1.2rem !important;
}

.fs-0_8 {
  font-size: .8rem !important;
}

.color924cff {
  color: #924cff;
}

.color00005 {
  color: rgba(0, 0, 0, .55) !important;
}

.eleMessage {
  border-radius: 16px !important;
  padding: 15px 12px !important;
}

.eleMessage .el-message__img {
  width: 50px;
  height: 50px;
}

.eleMessage .el-message__group {
  margin-left: 58px;
}

.eleMessage .el-message__group p {
  color: #000;
}

@media (hover: hover) {
  .favourite-on:hover .off, .favourite-off:hover .on {
    display: inline;
  }

  .favourite-on:hover .on, .favourite-off:hover .off {
    display: none;
  }
}

.clameSuccess .el-message-box .el-message-box__content .el-message-box__status {
  margin-top: 30px !important;
  font-size: 40px !important;
  position: static !important;
}

.clameSuccess .el-message-box .el-message-box__header .el-message-box__title {
  text-align: center !important;
  font-size: 26px !important;
  font-weight: 500 !important;
}

.clameSuccess .el-message-box .el-message-box__content .el-message-box__message a {
  word-wrap: break-word !important;
}

.clameSuccess .el-message-box .el-message-box__content .el-message-box__message {
  width: 100%;
  text-align: center;
  margin-left: 0 !important;
}

.clameSuccess .el-message-box .el-message-box__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.clameSuccess .el-message-box .el-message-box__btns {
  padding: 10px 20px 20px;
  text-align: center !important;
}

.clameSuccess .el-message-box .el-message-box__btns .el-button {
  margin-left: 0 !important;
  padding: 10px 50px !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/*# sourceMappingURL=index.69c61b6e.css.map */
