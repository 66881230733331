body{
  --bs-font-sans-serif: Lato,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
  --bs-body-font-family: var(--bs-font-sans-serif);
}

header a.moniker{
  margin-left: 15px;
}

.clipboard.copied{
  text-decoration: underline;
}

.btn-circle-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

code {
  padding: 0.5rem;
  line-height:1.5rem;
}

code p{
  margin-bottom: 0.5rem;
}
  
.favourite-on .on,
.favourite-off .off {
  display: inline;
}

.favourite-on .off,
.favourite-off .on{
  display: none;
}
.fs-6small{
  font-size: 15px !important;
}
.fs-1_2{
  font-size: 1.2rem !important;
}
.fs-0_8{
  font-size: 0.8rem !important;
}
.color924cff{
  color: #924cff;
}
.color00005{
  color: rgba(0, 0, 0, 0.55) !important;
}
.eleMessage{
  padding: 15px 12px !important;
  border-radius: 16px !important;
}
.eleMessage .el-message__img{
  width: 50px;
  height: 50px;
}
.eleMessage .el-message__group{
  margin-left: 58px;
}
.eleMessage .el-message__group p{
  color: #000;
}


@media (hover: hover) {
  .favourite-on:hover .off,
  .favourite-off:hover .on {
    display: inline;
  }

  .favourite-on:hover .on,
  .favourite-off:hover .off {
    display: none;
  }
}

.clameSuccess .el-message-box .el-message-box__content .el-message-box__status{
  margin-top: 30px !important;
  font-size: 40px !important;
  position: static !important;
}
.clameSuccess .el-message-box .el-message-box__header .el-message-box__title{
  font-size: 26px !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.clameSuccess .el-message-box .el-message-box__content .el-message-box__message a{
  word-wrap:break-word !important;
}
.clameSuccess .el-message-box .el-message-box__content .el-message-box__message {
  width: 100%;
  text-align: center;
  margin-left: 0 !important;
}
.clameSuccess .el-message-box .el-message-box__content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clameSuccess .el-message-box .el-message-box__btns{
  text-align: center !important;
  padding: 10px 20px 20px;
}
.clameSuccess .el-message-box .el-message-box__btns .el-button{
  padding: 10px 50px !important;
  margin-left: 0 !important;
}